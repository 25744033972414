/* eslint-disable prefer-destructuring */
import React, { useReducer } from 'react';

export const CotizadorContext = React.createContext([{}, () => { }]);

export function CotizadorProvider({ children }: {children: JSX.Element }) {
  const [state, dispatch] = useReducer(cotizadorReducer, {
    isOpen: false,
    formInfo: '',
    itemsAlq: [],
    itemsVta: [],
    isSendingForm: false,
    formSucceed: null,
    formError: null,
  });
  return (
    <CotizadorContext.Provider value={[state, dispatch]}>
      {children}
    </CotizadorContext.Provider>
  );
}

function cotizadorReducer(state: any, action: any) {
  switch (action.type) {
    case 'ADD_ITEM_ALQUILER': {
      const newItem = action.payload.item;
      if (newItem.imgs && newItem.imgs[0]) {
        newItem.img = newItem.imgs[0]; // when requested from detail page
      }
      const { index } = action.payload;
      if (state.itemsAlq.some((item: any) => item.cod === newItem.cod)) {
        if (index >= 0 && newItem.qty > 0) {
          const items = [...state.itemsAlq];
          items[index] = { ...newItem };
          return { ...state, itemsAlq: items };
        }
        // already exists: ignore
        return state;
      }
      return {
        ...state,
        itemsAlq: [...state.itemsAlq, { ...newItem, qty: 1 }],
      };
    }
    case 'ADD_ITEM_VENTA': {
      const newItem = action.payload.item;
      if (newItem.imgs && newItem.imgs[0]) {
        newItem.img = newItem.imgs[0]; // when requested from detail page
      }
      if (state.itemsVta.some((item: any) => item.cod === newItem.cod)) {
        // already exists: ignore
        return state;
      }
      return { ...state, itemsVta: [...state.itemsVta, newItem] };
    }
    case 'REMOVE_ITEM_ALQUILER': {
      const items = [...state.itemsAlq];
      items.splice(action.payload.index, 1);
      return { ...state, itemsAlq: items };
    }
    case 'REMOVE_ITEM_VENTA': {
      const items = [...state.itemsVta];
      items.splice(action.payload.index, 1);
      return { ...state, itemsVta: items };
    }
    case 'OPEN_COTIZADOR': {
      return { ...state, isOpen: true, formInfo: action.payload };
    }
    case 'CLOSE_COTIZADOR': {
      return {
        ...state,
        isOpen: false,
        isSendingForm: false,
        formSucceed: null,
        formError: null,
      };
    }
    case 'SENDFORM_REQUEST': {
      return {
        ...state,
        isSendingForm: true,
        formError: null,
        formSucceed: null,
      };
    }
    case 'SENDFORM_SUCCESS': {
      const newState = {
        ...state,
        isSendingForm: false,
        formError: null,
        itemsAlq: [],
        itemsVta: [],
        formSucceed: action.payload.message || 'El email fue enviado...',
      };
      return newState;
    }
    case 'SENDFORM_FAILURE': {
      return {
        ...state,
        isSendingForm: false,
        formError: action.payload.message || 'Unknown error',
        formSucceed: null,
      };
    }
    default:
      return state;
  }
}
export function addItemAlquiler(item: any, index: number = -1) {
  return { type: 'ADD_ITEM_ALQUILER', payload: { item, index } };
}
export function addItemVenta(item: any) {
  return { type: 'ADD_ITEM_VENTA', payload: { item } };
}
export function removeItemAlquiler(index: number) {
  return { type: 'REMOVE_ITEM_ALQUILER', payload: { index } };
}
export function removeItemVenta(index: number) {
  return { type: 'REMOVE_ITEM_VENTA', payload: { index } };
}
export function openCotizador(formInfo: any) {
  return { type: 'OPEN_COTIZADOR', payload: formInfo };
}
export function closeCotizador() {
  return { type: 'CLOSE_COTIZADOR' };
}
/**
 * Send Form
 */
export async function sendForm(dispatch: React.Dispatch<any>, formUrl: string, data: object) {
  const url = (process.env.REACT_APP_BACKEND_URL || '') + formUrl;
  dispatch({ type: 'SENDFORM_REQUEST' });

  try {
    const response = await fetch(url, {
      method: 'post',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    // only network errors trigger the exception (catch), others just return error info
    if (response.ok) {
      const json = await response.json();
      if (json.statusCode !== -1) {
        dispatch({
          type: 'SENDFORM_SUCCESS',
          payload: { message: 'Enviado con éxito' },
        });
      } else {
        dispatch({
          type: 'SENDFORM_FAILURE',
          payload: { message: json.errorMessage },
        });
      }
    } else {
      // handle error return
      dispatch({ type: 'SENDFORM_FAILURE', payload: response.statusText });
    }
  } catch (error) {
    // handle network exception
    dispatch({ type: 'SENDFORM_FAILURE', payload: error });
  }
}
