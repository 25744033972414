/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import './HighlightedProductsPanel.scss';
import 'react-multi-carousel/lib/styles.css';
import { bgImageStyle } from '../common/utils/utils';

export default function HighlightedProductsPanel({ langInfo, panel }: {langInfo: any, panel: any}) {
  const { t } = useTranslation();
  const { siteInfo } = langInfo;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const isExternalUrl = panel.button && panel.button.url
    && (panel.button.download || panel.button.url.includes('://'));

  return (
    <section className="HighlightedProductsPanel space--sm">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h3>{panel.title}</h3>
          </div>
          <div className="col-lg-6 col-md-6">
            {panel.button && isExternalUrl && (
            <a
              className="btn btn--primary type--uppercase float-right btn-panel"
              href={panel.button.url} target="_blank"
            >
              <span className="btn__text">{panel.button.copy}</span>
            </a>
            )}
            {panel.button && !isExternalUrl && (
              <Link
                className="btn btn--primary type--uppercase float-right btn-panel"
                to={panel.button.url}
              >
                <span className="btn__text">{panel.button.copy}</span>
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col carousel-col">
            <Carousel responsive={responsive} showDots>
              {panel.highlightedProducts.map(
                (product: any) => ProductCard(product, t, siteInfo, panel.isVenta, panel.isCustomListing),
              )}
            </Carousel>
            {panel.button && isExternalUrl && (
              <div className="btn-mobile">
                <a
                  className="btn btn--primary type--uppercase float-right"
                  href={panel.button.url} target="_blank"
                >
                  <span className="btn__text">{panel.button.copy}</span>&nbsp;
                </a>
              </div>
            )}
            {panel.button && !isExternalUrl && (
              <div className="btn-mobile">
                <Link
                  className="btn btn--primary type--uppercase float-right"
                  to={panel.button.url}
                >
                  <span className="btn__text">{panel.button.copy}</span>&nbsp;
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

function ProductCard(product: any, t: any, siteInfo: any, isVenta: boolean, isCustomListing: boolean) {
  const img = product.img || '/img/tepam/items/missing.png';
  let url = (isVenta ? siteInfo.ventaUrl : siteInfo.alquilerUrl) + '/' + product.urlX;
  if (isCustomListing) {
    url += '?isCL=1';
  }

  return (
    <div key={isVenta ? product.cod : product.codCat} className="slider-item">
      <div className="feature">
        <div
          className="feature__image"
          style={bgImageStyle(img)}
         />
        <div className="feature__body">
          <h4>{product.nombre}</h4>
          {isVenta ? (
            <p>
              {product.anio && (
                <span>
                  {t('Año', 'Año?')}: {product.anio + ' '}
                  {product.uso && (
                    <>
                      - {t('Uso', 'Uso?')}: {product.uso}
                    </>
                  )}
                </span>
              )}
              {product.estado && (
                <span>
                  {t('Estado', 'Estado?')}: {product.estado}
                </span>
              )}
              {product.pais && (
                <span>
                  {t('País', 'País?')}: {product.pais}
                </span>
              )}
            </p>
          ) : (
            <p>
              {product.txPotencia && (
              <span>
                {t('Potencia', 'Potencia?')} : {product.txPotencia} HP
              </span>
              )}
              {product.txPesoOperativo && (
              <span>
                {t('Peso operativo', 'Peso operativo?')} : {product.txPesoOperativo} Ton
              </span>
              )}
              {product.txProfundidad && (
              <span>
                {t('Profundidad', 'Profundidad?')} : {product.txProfundidad} m
              </span>
              )}
              {product.txCapacidad && (
              <span>
                {t('Capacidad', 'Capacidad?')} : {product.txCapacidad}{' '}
                {product.unid}
              </span>
              )}
              {product.tracc && (
              <span>
                {t('Tracción', 'Tracción?')} : {product.tracc}
              </span>
              )}
            </p>
          )}
          <Link to={url}>
            <span>{t('Ver más', 'Ver más?')}</span>
            <i className="material-icons">trending_flat</i>
          </Link>
        </div>
      </div>
    </div>
  );
}
