/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import './AlquilerFiltersSimple.scss';

export default function AlquilerFiltersSimple({ listName, list, currentValue, handleChange } : {
  listName: string;
  list: any[];
  currentValue: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  }) {
//
  if (!list) {
    return null;
  }

  return (
    <div className="AlquilerFiltersSimple form-group">
      <label htmlFor="selectedGrp" className=" filter-label">{listName}</label>
      <div className="input-select">
        <select name="selectedGrp" value={currentValue} onChange={handleChange}>
          <option className="select__option" key="-" value="" />
          {list.map((elem) => (
            <option className="select__option" key={elem.cod} value={elem.cod}>
              {elem.nomb}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
