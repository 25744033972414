import React from 'react';
import './SimpleHeaderBar.scss';

export default function NoHeaderBar() {
  return (
    <div className="SimpleHeaderBar">
      <div className="header-box">
        <div className="nav-spacer-box" />
        <div className="bar-position-box">
          <div className="bar-absolute-box wf-visible">
            <div className="bar-title-box container" />
          </div>
        </div>
      </div>
    </div>
  );
}
