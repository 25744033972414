import React from 'react';
import './VentaFiltersMulti.scss';
import Select from 'react-select';

export default function VentaFiltersMulti({ listName, list, currentValue, handleChange } : {
    listName: string;
    list: any[];
    currentValue: string[];
    handleChange: (e: any) => void
    }) {
  const value = currentValue.length === 0
    ? []
    : currentValue.map((s) => ({ value: s, label: s }));

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className="ventaFiltersMulti form-group">
      <label htmlFor={listName} className=" filter-label">{listName}</label>
      <Select
        isMulti
        name={listName}
        options={list}
        className="input-select"
        classNamePrefix="select"
        value={value}
        delimiter="-"
        onChange={handleChange}
      />
    </div>
  );
}
