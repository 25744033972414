/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
// import { useTranslation } from "react-i18next";

function Footer({ langInfo }: {langInfo: any}) {
  const { siteInfo } = langInfo;

  const handleChangeLang = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    window.location.href = event.currentTarget.href;
    event.preventDefault();
  };
  // translation
  // const { t } = useTranslation();

  return (
    <footer className="text-center-xs space--xs bg--primary">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <ul className="list-inline">
              {siteInfo.headerLinks
                && siteInfo.headerLinks.map((lnk: any) => (
                  <li key={lnk.text}><Link to={lnk.href}>{lnk.text}</Link></li>
                ))}
            </ul>
          </div>
          <div className="col-sm-5 text-right text-center-xs">
            {siteInfo.lang === 'es-ES' ? (
              <a href="/en" onClick={handleChangeLang}>English</a>
            ) : (
              <a href="/es" onClick={handleChangeLang}>Español</a>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7">
            <span className="type--fine-print">
              © <span className="update-year">2020</span> Tepam
            </span>
            {siteInfo.lang === 'es-ES' ? (
              <Link className="type--fine-print" to="/es/terminos-y-condiciones">
                Términos y condiciones
              </Link>
            ) : (
              <Link className="type--fine-print" to="/en/terms-conditions">
                Terms and Conditions
              </Link>
            )}
          </div>
          <div className="col-sm-5 text-md-right text-center-xs">
            <ul className="social-list list-inline list--hover">
              {siteInfo.socialMediaLinks
                && siteInfo.socialMediaLinks.map((lnk: any) => (
                  <li key={lnk.text}>
                    <a href={lnk.href} aria-label={lnk.text} target="_blank" rel="noopener noreferrer">
                      <i className={`socicon socicon-${lnk.text} icon icon--xs`} />
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
