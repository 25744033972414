import { TFunction } from 'react-i18next';
import { toLowerAscii } from '../common/utils/utils';

/**
 * Get search suggestions (array of sections or array of results)
 */
export default function search(
  t: TFunction<string>,
  siteInfo: any,
  searchTree: any,
  searchVtaEqp: any,
  showAV: string,
  value: string,
) : any[] {
  if (!value) {
    return [];
  }
  // determine which results to build
  const buildA = showAV.includes('A');
  const shouldBuildV = showAV.includes('V');
  const buildV = shouldBuildV && searchVtaEqp;

  // filter searchTree based on value
  const grupos = doSearch(searchTree, buildV, value);
  if (grupos.length === 0) return [];

  // prepare search results skeleton
  const skel = [
    {
      sectionTitle: t('Alquiler', 'Alquiler?'),
      sectionOptions: [],
    },
    {
      sectionTitle: t('Venta', 'Venta?'),
      sectionOptions: [],
    },
  ];

  // prepare results for each section
  const results: Array<any|undefined> = [undefined, undefined];
  if (buildA) {
    // build results for Alq groups
    results[0] = buildResults(
      grupos.filter((g) => g.isAlq),
      siteInfo.alquilerUrl,
      false,
    );
  }
  if (shouldBuildV) {
    // build results for Vta groups
    results[1] = searchVtaEqp
      ? buildResults(
        grupos.filter((g) => g.cantV > 0),
        siteInfo.ventaUrl,
        true,
      )
      : [{ name: `** ${t('Cargando', 'Cargando')}...`, url: '' }]; // not loaded yet
  }

  // if both sections (header search) return sections array
  if (buildA && shouldBuildV) {
    const sections: any[] = [];
    results.forEach((r: any, i) => {
      if (r.length > 0) {
        sections.push(buildSection(skel, i, r));
      }
    });
    return sections;
  }
  // return results for the only section required (panel Alq or Vta)
  return results[buildA ? 0 : 1];
}

/**
 * Build a section element, when both sections are required
 */
function buildSection(skel: any, idx: number, results: any) {
  return {
    sectionTitle: skel[idx].sectionTitle,
    sectionOptions: results,
  };
}

/**
 * Build the results array for a single section
 */
function buildResults(grupos: any, baseUrl: string, includeCantV: boolean) {
  return grupos.map((g: any) => ({
    name: g.nomb + (includeCantV ? ` (${g.cantV})` : ''),
    url: `${baseUrl}/${g.urlX}`,
    tx: g.tx.trim(),
  }));
}

/**
 * Perform the actual search on searchTree, return an array of groups matching the value
 */
function doSearch(searchTree: any, buildV: boolean, value: string) {
  const words = toLowerAscii(value)
    .split(' ')
    .filter((w) => w && w.length >= 3);
  if (words.length === 0) {
    return [];
  }

  // first word
  let word = words.shift() as string;
  const grupos = selectWithFirstWord(searchTree, buildV, word);

  // iter: other words
  while (grupos.length > 0 && words.length > 0) {
    word = words.shift() as string;
    filterByWord(grupos, buildV, word);
  }

  // return filtered groups
  return grupos;
}

/**
 * Filter existing nodes by a single word
 */
function selectWithFirstWord(searchTree: any, buildV: boolean, word: string) {
  const grupos: any[] = [];
  searchTree.forEach((fam: any) => {
    fam.grupos.forEach((grp: any) => {
      // iter grps
      if (grp.kwds.includes(word)) {
        // grp contains word: add to results
        grupos.push(buildGrp(grp, '', grp.isAlq, grp.cantV, [], grp.categs));
      } else {
        // grp does not contain word: lookup in categs
        const categs: any[] = [];
        let isAlq = false;
        let cantV = 0;
        grp.categs.forEach((cat: any) => {
          // iter categs
          if (cat.kwds.includes(word)) {
            // categ contains word: add to pending categs
            categs.push(buildCateg(cat, cat.isAlq, cat.cantV, [], cat.equipos));
            isAlq = isAlq || cat.isAlq;
            cantV += cat.cantV;
          } else if (buildV && cat.equipos) {
            // categ does not contain word and V enabled: lookup in eqps
            const eqps = cat.equipos.filter((eqp: any) => eqp.kwds.includes(word));
            if (eqps.length > 0) {
              // some eqps found: add categ to pending categs
              categs.push(
                buildCateg(
                  cat,
                  false,
                  eqps.length,
                  eqps.map((eqp: any) => eqp.kwds),
                  undefined,
                ),
              );
              cantV += eqps.length;
            }
          }
        });
        if (categs.length > 0) {
          // categs found, add grp to results
          grupos.push(buildGrp(grp, word, isAlq, cantV, categs));
        }
      }
    });
  });
  return grupos;
}

/**
 * Filter existing nodes by a single word - remove non-matches
 */
function filterByWord(grupos: any, buildV: boolean, word: string) {
  // process groups that do not contain the word
  grupos
    .filter((grp: any) => !grp.kwds.includes(word))
    .forEach((grp: any) => {
      // grp does not contain word: lookup in categs
      const categs: any[] = [];
      let isAlq = 0;
      let cantV = 0;

      // select source array
      const isSrcCateg = !!grp.srcCategs;
      const srcCategs = grp.srcCategs || grp.categs;
      srcCategs.forEach((cat: any) => {
        // iter categs
        if (cat.kwds.includes(word)) {
          // categ contains word: add to pending categs
          if (isSrcCateg) {
            categs.push(buildCateg(cat, cat.isAlq, cat.cantV, [], cat.equipos));
          } else {
            categs.push(cat);
          }
          isAlq = isAlq || cat.isAlq;
          cantV += cat.cantV;
        } else if (buildV) {
          // categ does not contain word and V enabled: lookup in eqps
          const eqps = cat.srcEquipos ? cat.srcEquipos
            .filter((eqp: any) => eqp.kwds.includes(word))
            .map((eqp: any) => eqp.kwds)
            : !cat.equipos ? []
              : isSrcCateg ? cat.equipos
                .filter((eqp: any) => eqp.kwds.includes(word))
                .map((eqp: any) => eqp.kwds)
                : cat.equipos.filter((eqp: any) => eqp.includes(word));

          if (eqps.length > 0) {
            // some eqps found: add categ to pending categs
            categs.push(buildCateg(cat, false, eqps.length, eqps, undefined));
            cantV += eqps.length;
          }
        }
      });
      if (categs.length > 0) {
        // categs found, update group info
        grp.isAlq = isAlq;
        grp.cantV = cantV;
        grp.categs = categs;
        grp.srcCategs = undefined;
        grp.tx = `${grp.tx} ${word}`;
      } else {
        // no categs match: remove group
        grupos.splice(
          grupos.findIndex((g: any) => g === grp),
          1,
        );
      }
    });
}

function buildGrp(grp: any, tx: string,
  isAlq: boolean, cantV: number, categs: any[], srcCategs: any[] | undefined = undefined) {
  return {
    nomb: grp.nomb,
    urlX: grp.urlX,
    tx,
    kwds: grp.kwds,
    isAlq,
    cantV,
    categs,
    srcCategs,
  };
}
function buildCateg(categ: any, isAlq: boolean, cantV: number, equipos: any, srcEquipos: any) {
  return {
    kwds: categ.kwds,
    isAlq,
    cantV,
    equipos,
    srcEquipos,
  };
}
