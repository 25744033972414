/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CotizadorContext, openCotizador } from '../cotizador/CotizadorContext';
import SearchBox from '../search/SearchBox';
import { mediaUrl } from '../common/utils/utils';
import './Header.scss';

function Header({ langInfo }: {langInfo: any}) {
  const { siteInfo } = langInfo;
  const [cotizador, cotizadorDispatch] = useContext<any>(CotizadorContext);
  const qty = cotizador.itemsAlq.length + cotizador.itemsVta.length;
  const { t } = useTranslation();
  const logoUrl = mediaUrl('/img/tepam/LOGO-TEPAM-' + siteInfo.lang.substring(0, 2) + '.png');

  return (
    <div className="Header nav-container w-100 bg--primary-2">
      <Navbar expand="lg" className="w-100" variant="dark">
        <div className="container">
          <Link className="navbar-brand" to={siteInfo.homeUrl}>
            <img
              className="logo"
              alt="logo"
              src={logoUrl}
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {siteInfo.headerLinks
                && siteInfo.headerLinks.map((lnk: any, idx: number) => (
                  <Link key={idx} className="nav-link" to={lnk.href}>
                    {lnk.text}
                  </Link>
                ))}
            </Nav>
            <div className="input-group mr-3">
              <SearchBox langInfo={langInfo} />
            </div>
            {qty > 0 && (
              <button type="button"
                className="btn btn-cotizador btn--primary"
                onClick={() => cotizadorDispatch(openCotizador(null))}
              >
                <span className="btn__text">
                  {t('Cotizador', 'Cotizador?')}
                </span>
                {qty > 0 && (
                  <span className="Header-cotizador">&nbsp;({qty})</span>
                )}
              </button>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default Header;
