import React from 'react';
import { bgImageStyle } from '../common/utils/utils';
import CtaButton from '../common/components/CtaButton';
import './CardImagePanel.scss';

export default function CardImagePanel({ panel }: {panel: any}) {
  const imageClass = `background-image-holder${
    panel.imageResizeType === 'Contain' ? ' background-image-contain'
      : panel.imageResizeType === 'Fill' ? ' background-image-fill'
        : ''}`;
  return (
    <section
      className={
        `CardImagePanel imageblock space--xs${
          panel.hasGrayBackground ? ' hasGrayBackground' : ''}`
      }
    >
      <div className="container">
        <div
          className={
            `cardContent${panel.isImageFlipped ? ' flippedCard' : ''}`
          }
        >
          <div className="col-lg-6 pos-right mobile-height">
            {panel.image && panel.image.url && (
              <div
                className={imageClass}
                style={bgImageStyle(panel.image.url)}
              />
            )}
          </div>
          <div className="col-lg-5">
            <div className="panel-tagline">{panel.tagline}</div>
            <h3>{panel.title}</h3>
            <div
              className="lead rtf"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: panel.body }}
            />
            <CtaButton cta={panel.cta} />
          </div>
        </div>
      </div>
    </section>
  );
}
