/* eslint-disable react/no-danger */
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SiteContextValue } from '../site/SiteContext';
import { CotizadorContext } from '../cotizador/CotizadorContext';
import PanelWrapper from '../common/components/PanelWrapper';
import NoHeaderBar from '../common/components/NoHeaderBar';
import Cotizador from '../cotizador/Cotizador';
import Header from './Header';
import Footer from './Footer';
import './Page.scss';
import { txLoadingPage } from '../common/utils/preTranslate';
import { usePageView } from '../common/utils/gtagHooks';
/**
 * Page component - stateful, fetches page data as required
 */
export default function Page({ langInfo, siteContextValue }: { langInfo: any, siteContextValue: SiteContextValue }) {
  const {
    infos, errors, dispatch,
  } = siteContextValue;
  const { siteInfo } = langInfo;
  const { pathname } = useLocation();

  // cotizador
  const [cotizador] = useContext<any>(CotizadorContext);

  // get data path for the page
  const path = reducePageUrl(langInfo.lang, pathname.substring(4));
  const pageInfo = infos[path];
  const pageInfoError = errors[path];

  // require page  when not available
  useEffect(() => {
    if (!pageInfo) {
      dispatch({ type: 'ADD_REQUEST', path });
    }
  }, [pageInfo, path, dispatch]);

  // Google Analytics
  const isProd = siteInfo.env.isProd as boolean || false;

  usePageView(isProd);

  // if cotizador is open, show this instead of page
  if (cotizador.isOpen && siteInfo) { // show cotizador
    return (
      <GoogleReCaptchaProvider reCaptchaKey={siteInfo.reCaptchaKey}>
        <Cotizador langInfo={langInfo} />
      </GoogleReCaptchaProvider>
    );
  }

  // render
  return (
    <>
      <CookieConsent
        buttonClasses="CustomCookieConsent__button"
        containerClasses="CustomCookieConsent"
        sameSite="strict"
        buttonText={siteInfo.cookiesBanner.bannerButtonCopy}
        overlayClasses="CustomCookieConsent-overlay"
      >
        <div className="rtf" dangerouslySetInnerHTML={{ __html: siteInfo.cookiesBanner.bannerCopy }} />
      </CookieConsent>

      {(!pageInfo || !pageInfo.supressHeader) && <Header langInfo={langInfo} />}

      <div className="main-container">
        {pageInfo ? (
          <>
            <Helmet>
              <title>Tepam Rental - {pageInfo.pageTitle}</title>
              <meta name="description" content={pageInfo.metaDescription} />
            </Helmet>
            {pageInfo.panels.map((panel:any, key:string) => (
              <PanelWrapper {...{
                key, langInfo, pageInfo, panel,
              }}
              />
            ))}
            {siteInfo.env.isLocal && pageInfo.showDevDetails && (
              <div className="container"><pre>{JSON.stringify(pageInfo, null, '  ')}</pre></div>
            )}
          </>
        ) : (
          <div className="panel">
            <NoHeaderBar />
            <div className="container">
              {pageInfoError ? (
                <div className="text-danger">{pageInfoError}</div>
              ) : (
                <div className="text-info">{txLoadingPage(langInfo.lang)}...</div>
              )}
            </div>
          </div>
        )}
        {(!pageInfo || !pageInfo.supressFooter) && <Footer langInfo={langInfo} />}
      </div>
    </>
  );
}
/**
* Reduce multiple navigational URLs to a standard one to avoid multiple fetch operations
* and multiple changes in dependencies
*
* For example:
*   alquiler => (unchanged)
*   alquiler/movimiento-de-suelo  => alquiler
*   alquiler/movimiento-de-suelo/rodillos-compactadores  => alquiler
*   alquiler/movimiento-de-suelo/rodillos-compactadores/rodillo-suelo  => alquiler-detalle
*/
function reducePageUrl(lang:string, path:string) {
  if (lang === 'es-ES') {
    if (path.length <= 1) {
      // root
      return 'inicio';
    }
    if (path.startsWith('alquiler/')) {
      return path.split('/').length < 4 ? 'alquiler' : 'alquiler-detalle';
    }
    if (path.startsWith('venta/')) {
      return path.split('/').length < 5 ? 'venta' : 'venta-detalle';
    }
  } else if (lang === 'en-US') {
    if (path.length <= 1) {
      // root
      return 'home';
    }
    if (path.startsWith('rental/')) {
      return path.split('/').length < 4 ? 'rental' : 'rental-detail';
    }
    if (path.startsWith('sales/')) {
      return path.split('/').length < 5 ? 'sales' : 'sales-detail';
    }
  }
  return path.endsWith('/') ? path.substring(0, path.length - 1) : path;
}
