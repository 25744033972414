import React, { useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CotizadorContext, addItemVenta } from '../../cotizador/CotizadorContext';
import { mediaUrl } from '../../common/utils/utils';
import './VentaItems.scss';
import './VentaItemsCols.scss';
import { sendGAdsConversion } from '../../common/utils/gtagHooks';
import { SiteContext, SiteContextValue } from '../../site/SiteContext';

export default function VentaItems({ panel, data }: { panel: any; data: any }) {
  const { items, basePath } = data;
  const [, cotizadorDispatch] = useContext<any>(CotizadorContext);
  const { t } = useTranslation();
  const { infos } = useContext<SiteContextValue | undefined>(SiteContext) as SiteContextValue;
  const langInfo = infos['/site/langInfo'];
  const { siteInfo } = langInfo;

  if (items.length === 0) {
    return (<div className="lead noResult"><div className="col-lg-6 col-md-10">{panel.noResults}</div></div>);
  }

  const handleAddCotizador = (item: any) => {
    // ------------ dispatch with GAds Conversion -----------------------
    sendGAdsConversion(() => cotizadorDispatch(addItemVenta(item)), siteInfo.env.isProd);
  };

  return (
    <div className="column-items">
      {items.map((item: any) => ItemCard(basePath, item, t, handleAddCotizador))}
    </div>
  );
}

function ItemCard(basePath: string, item: any, t: TFunction<string>, handleAddCotizador: (it: any) => void) {
  const img = mediaUrl(item.img || '/img/tepam/items/missing.png');
  const nomb = item.mca + ' ' + item.mod;

  return (
    <div key={item.cod} className="column-item">
      <div className="card card-2 text-center">
        <div className="card__top">
          <img alt={nomb} src={img} />
        </div>
        <div className="card__body">
          <p>{item.nombCat}</p>
          <h4>{nomb}</h4>
          <ul>
            <li>
              {t('Año', 'Año?')}: {item.an + ' '}
              {item.km && (<> - Km: {item.km} </>)}
              {item.hs && (<> - Hs: {item.hs} </>)}
            </li>
            {item.est && (
            <li>{t('Estado', 'Estado?')}: {item.est}</li>
            )}
            {item.pais && (
            <li>{t('País', 'País?')}: {item.pais}</li>
            )}
          </ul>
        </div>
        <div className="card__bottom">
          <button type="button" className="mt-0" onClick={() => handleAddCotizador(item)}>
            <div className="card__action">
              <i className="material-icons">add</i>
              <span className="h6 type--uppercase"><p>{t('Cotizar', 'Cotizar?')}</p></span>
            </div>
          </button>
          <Link to={basePath + '/' + item.urlX}>
            <div className="card__action">
              <span className="h6 type--uppercase"><p>{t('Ver más', 'Ver más?')}</p></span>
              <i className="material-icons">trending_flat</i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
