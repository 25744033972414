/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { History } from 'history';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import VentaFiltersRange from './VentaFiltersRange';
import VentaFiltersSimple from './VentaFiltersSimple';
import VentaFiltersMulti from './VentaFiltersMulti';
import './VentaFilters.scss';

export default function VentaFilters({ data }: { data: any }) {
  const { t } = useTranslation();
  const history = useHistory();
  const initialFilterText = t('Mostrar', 'Mostrar?') + ' ' + t('Filtros', 'Filtros?').toLowerCase();
  const [filterText, setFilterText] = useState(initialFilterText);

  const handleToggle = () => setFilterText(toggleFilterText(t, filterText));

  return (
    <>
      <Accordion className="h-100">
        <div className="ventaFilters">
          <div className=" ventaFilters__container">
            <div className="column-filters w-100">
              <div className="filters__title pl-3 pr-3">
                <Accordion.Toggle
                  onClick={handleToggle}
                  className="header-button m-0 p-2"
                  as={Button}
                  variant="link"
                  eventKey="0"
                >
                  <i className="material-icons pr-3">filter_list</i>
                  {filterText}
                </Accordion.Toggle>

                <Button variant="link" className="header-button m-0" onClick={() => resetFilters(history, data)}>
                  {t('Limpiar', 'Limpiar?')}
                </Button>
              </div>
              <div className="filters__header">
                <div className="header-text">{t('Filtros', 'Filtros?')}</div>

                <button type="button" className="header-button" onClick={() => resetFilters(history, data)}>
                  {t('Limpiar', 'Limpiar?')}
                </button>
              </div>
              <Accordion.Collapse eventKey="0">
                <form className="pl-md-3 pr-md-3 w-100">
                  <VentaFiltersSimple
                    listName={t('País', 'País?')}
                    list={data.paises.map((p: string) => ({ cod: p, nomb: p }))}
                    currentValue={data.pais ? data.pais : ''}
                    handleChange={(e) => changePaisFilter(history, data, e.target.value)}
                  />
                  {data.familia && (
                    <>
                      <VentaFiltersSimple
                        listName={t('Familia', 'Familia?')}
                        list={data.familias.filter(
                          (fam: any) => fam.cantV > 0 || (data.familia && fam.cod === data.familia.cod),
                        )}
                        currentValue={data.familia ? data.familia.cod : ''}
                        handleChange={(e) => changeFamiliaFilter(history, data, e.target.value)}
                      />
                      <VentaFiltersSimple
                        listName={t('Grupo', 'Grupo?')}
                        list={data.familia.grupos.filter(
                          (grp: any) => grp.cantV > 0 || (data.grupo && grp.cod === data.grupo.cod),
                        )}
                        currentValue={data.grupo ? data.grupo.cod : ''}
                        handleChange={(e) => changeGrupoFilter(history, data, e.target.value)}
                      />
                      {data.grupo && (
                        <VentaFiltersSimple
                          listName={t('Categoria', 'Categoria?')}
                          list={data.grupo.categs.filter(
                            (cat: any) => cat.cantV > 0 || (data.categ && cat.cod === data.categ.cod),
                          )}
                          currentValue={data.categ ? data.categ.cod : ''}
                          handleChange={(e) => changeCategFilter(history, data, e.target.value)}
                        />
                      )}
                      <VentaFiltersMulti
                        listName={t('Marca', 'Marca?')}
                        list={
                          !data.marcas ? null : data.marcas.map((elem: any) => ({
                            value: elem.nomb,
                            label: elem.nomb,
                          }))
                        }
                        currentValue={data.selectedMarcas}
                        handleChange={(elems) => changeMarcaFilter(history, data, elems)}
                      />

                      <VentaFiltersMulti
                        listName={t('Modelo', 'Modelo?')}
                        list={
                          !data.modelos ? null : data.modelos.map((elem: string) => ({
                            value: elem,
                            label: elem,
                          }))
                        }
                        currentValue={data.selectedModelos}
                        handleChange={(elems) => changeModeloFilter(history, data, elems)}
                      />

                      {data.items && (
                        <div className="VentaFiltersSimple form-group">
                          <label htmlFor="searchText" className="filter-label">
                            {' ' + t('Refinar búsqueda (texto)', 'Refinar búsqueda (texto)?')}
                          </label>
                          <input
                            type="text"
                            name="searchText"
                            className="form-control filter-input"
                            value={data.searchText || ''}
                            onChange={(e) => changeTextFilter(history, data, e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') e.preventDefault();
                            }}
                          />
                        </div>
                      )}

                      {data.rangeFilters.map((filter: any, idx: number) => (
                        <VentaFiltersRange key={idx} data={data} filter={filter} />
                      ))}
                    </>
                  )}
                </form>
              </Accordion.Collapse>
            </div>
          </div>
        </div>
      </Accordion>
    </>
  );
}
function toggleFilterText(t: TFunction<string>, filterText: string) {
  return filterText === t('Filtros', 'Filtros?')
    ? t('Mostrar', 'Mostrar?') + ' ' + t('Filtros', 'Filtros?').toLowerCase()
    : t('Filtros', 'Filtros?');
}
/*
  Filter handling functions
*/
export function resetFilters(history: History<unknown>, data: any) {
  history.push(data.basePath as string);
}
export function changePaisFilter(history: History<unknown>, data: any, pais: string) {
  const urlParams = new URLSearchParams(data.search);
  if (pais) {
    urlParams.set('pais', pais);
  } else {
    urlParams.delete('pais');
  }
  history.push(buildUrl(data.currentPath, urlParams));
}
export function changeFamiliaFilter(history: History<unknown>, data: any, cod: string) {
  const pais = data.urlParams.get('pais');
  const urlParams = new URLSearchParams();
  if (pais) {
    urlParams.set('pais', pais);
  }
  const familia = cod ? data.familias.find((f: any) => f.cod === cod) : undefined;
  history.push(buildUrl(data.basePath
    + (familia ? `/${familia.urlS}` : ''), urlParams));
}
export function changeGrupoFilter(history: History<unknown>, data: any, cod: string) {
  const pais = data.urlParams.get('pais');
  const urlParams = new URLSearchParams();
  if (pais) {
    urlParams.set('pais', pais);
  }
  const { familia } = data;
  const grupo = familia ? familia.grupos.find((g: any) => g.cod === cod) : null;
  history.push(buildUrl(data.basePath
    + (familia ? `/${familia.urlS}${grupo ? `/${grupo.urlS}` : ''}` : ''),
  urlParams));
}
export function changeCategFilter(history: History<unknown>, data: any, cod: string) {
  const pais = data.urlParams.get('pais');
  const urlParams = new URLSearchParams();
  if (pais) {
    urlParams.set('pais', pais);
  }
  const { familia } = data;
  const { grupo } = data;
  const categ = grupo ? grupo.categs.find((c: any) => c.cod === cod) : null;
  history.push(buildUrl(data.basePath
    + (familia ? `/${familia.urlS}${grupo ? `/${grupo.urlS}${categ ? `/${categ.urlS}` : ''}` : ''}` : ''),
  urlParams));
}
export function changeMarcaFilter(history: History<unknown>, data: any, elems: any) {
  const pais = data.urlParams.get('pais');
  const urlParams = new URLSearchParams();
  if (pais) {
    urlParams.set('pais', pais);
  }
  if (elems && elems.length > 0) {
    urlParams.set('marca', elems.map((e: any) => e.value).join('--'));
  }
  history.push(buildUrl(data.currentPath, urlParams));
}
export function changeModeloFilter(history: History<unknown>, data: any, elems: any) {
  const pais = data.urlParams.get('pais');
  const mca = data.urlParams.get('marca');
  const urlParams = new URLSearchParams();
  if (pais) {
    urlParams.set('pais', pais);
  }
  urlParams.set('marca', mca);
  if (elems && elems.length > 0) {
    urlParams.set('modelo', elems.map((e: any) => e.value).join('--'));
  }
  history.push(buildUrl(data.currentPath, urlParams));
}
export function changeTextFilter(history: History<unknown>, data: any, text: string) {
  const urlParams = new URLSearchParams(data.search);
  if (text) {
    urlParams.set('tx', text.toLowerCase());
  } else {
    urlParams.delete('tx');
  }
  history.push(buildUrl(data.currentPath, urlParams));
}
/**
 * build a URL from urlParams
 */
function buildUrl(path: string, urlParams: URLSearchParams) {
  return `${path}?${urlParams.toString()}`;
}
