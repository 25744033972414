/* eslint-disable react/no-danger */
import React from 'react';
import { bgImageStyle } from '../utils/utils';

export default function BigHeroBlock(props: any) {
  const { pageTitle, title, subtitle, image } = props;
  return (
    <div>
      <section
        className="BigHeroBlock-desktop hidden-xs cover height-50 imagebg text-center section--ken-burns"
        data-overlay="5"
      >
        <div
          className="background-image-holder"
          style={bgImageStyle(image?.url)}
         />
        <div className="container pos-vertical-center">
          <div className="row pt-3">
            <div className="col-md-8 col-lg-7">
              <h1>
                <strong>{title}</strong>
              </h1>
              <p
                className="lead rtf"
                dangerouslySetInnerHTML={{ __html: subtitle }}
               />
            </div>
          </div>
        </div>
      </section>
      <section className="BigHeroBlock-mobile visible-xs cover space--0 bg--primary-1">
        <div className="nav-spacer-box" />
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col text-center pr-3 pt-3 pb-3">
              <h1>{pageTitle}</h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
