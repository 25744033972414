import React, { useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CotizadorContext, addItemAlquiler } from '../../cotizador/CotizadorContext';
import { mediaUrl } from '../../common/utils/utils';
import './AlquilerItems.scss';
import './AlquilerItemsCols.scss';

export default function AlquilerItems({ panel, data }: { panel: any; data: any }) {
  const { items, basePath } = data;
  const [, cotizadorDispatch] = useContext<any>(CotizadorContext);
  const { t } = useTranslation();

  if (items.length === 0) {
    return (<div className="lead noResult"><div className="col-lg-6 col-md-10">{panel.noResults}</div></div>);
  }

  return (
    <div className="column-items">
      {items.map((item: any) => ItemCard(basePath, item, t, cotizadorDispatch))}
    </div>
  );
}

function ItemCard(basePath: string, item: any, t: TFunction<string>, cotizadorDispatch: any) {
  const img = mediaUrl(item.img || '/img/tepam/items/missing.png');
  const txPot = buildRange(item.pt0, item.pt1, 'HP');
  const txPeso = buildRange(item.po0, item.po1, 'Tn');
  const txProf = buildRange(item.pr0, item.pr1, 'm');
  const txCap = buildRange(item.ca0, item.ca1, item.um);

  let url = basePath + '/' + item.urlX;
  const n = url.lastIndexOf('/');
  url = url.substring(0, n + 1) + item.cod + '--' + url.substring(n + 1);

  return (
    <div key={item.cod} className="column-item">
      <div className="card card-2 text-center">
        <div className="card__top">
          <img alt={item.nomb} src={img} />
        </div>
        <div className="card__body">
          <h4>{item.nomb}</h4>
          <ul>
            {txPot && (
            <li>{t('Potencia', 'Potencia?')}: {txPot}</li>
            )}
            {txPeso && (
            <li>{t('Peso operativo', 'Peso operativo?')}: {txPeso}</li>
            )}
            {txProf && (
            <li>{t('Profundidad', 'Profundidad?')}: {txProf}</li>
            )}
            {txCap && (
            <li>{t('Capacidad', 'Capacidad?')}: {txCap} {item.unid}</li>
            )}
            {item.tracc && (
            <li>{t('Tracción', 'Tracción?')}: {item.tracc}</li>
            )}
          </ul>
        </div>
        <div className="card__bottom">
          <button
            type="button"
            className="mt-0"
            onClick={() => cotizadorDispatch(addItemAlquiler(item))}
          >
            <div className="card__action">
              <i className="material-icons">add</i>
              <span className="h6 type--uppercase">
                <p>{t('Cotizar', 'Cotizar?')}</p>
              </span>
            </div>
          </button>
          <Link to={url}>
            <div className="card__action">
              <span className="h6 type--uppercase">
                <p>{t('Ver más', 'Ver más?')}</p>
              </span>
              <i className="material-icons">trending_flat</i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

function buildRange(min: number, max: number, um: string) {
  if (!min || !max || !um) {
    return undefined;
  }
  return min === max ? min.toString() + ' ' + um : min.toString() + ' - ' + max.toString() + ' ' + um;
}
