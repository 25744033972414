import React from 'react';
import { Link } from 'react-router-dom';
import { bgImageStyle } from '../../common/utils/utils';

export default function AlquilerGrupos({ grupos, basePath }: { grupos: any[], basePath: string }) {
  return (
    <div className="row">
      {grupos.filter((grp) => grp.isAlq).map((grp) => (
        <div key={grp.cod} className="column-item bg--secondary mb-4 border--round grpily-item">
          <Link to={basePath + '/' + grp.urlX} className="block w-100">
            <div className="mb-0 feature feature-7 boxed text-center imagebg" data-overlay="3">
              <div className="background-image-holder" style={bgImageStyle(grp.img)} />
              <h4 className="pos-vertical-center">{grp.nomb}</h4>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
