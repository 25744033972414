/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { History } from 'history';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import AlquilerFiltersRange from './AlquilerFiltersRange';
import AlquilerFiltersSimple from './AlquilerFiltersSimple';
import './AlquilerFilters.scss';

export default function AlquilerFilters({ data }: { data: any }) {
  const { t } = useTranslation();
  const history = useHistory();
  const initialFilterText = t('Mostrar', 'Mostrar?') + ' ' + t('Filtros', 'Filtros?').toLowerCase();
  const [filterText, setFilterText] = useState(initialFilterText);

  const handleToggle = () => setFilterText(toggleFilterText(t, filterText));

  return (
    <>
      <Accordion className="h-100">
        <div className="ventaFilters">
          <div className=" ventaFilters__container">
            <div className="column-filters w-100">
              <div className="filters__title pl-3 pr-3">
                <Accordion.Toggle
                  onClick={handleToggle}
                  className="header-button m-0 p-2"
                  as={Button}
                  variant="link"
                  eventKey="0"
                >
                  <i className="material-icons pr-3">filter_list</i>
                  {filterText}
                </Accordion.Toggle>

                <Button variant="link" className="header-button m-0" onClick={() => resetFilters(history, data)}>
                  {t('Limpiar', 'Limpiar?')}
                </Button>
              </div>
              <div className="filters__header">
                <div className="header-text">{t('Filtros', 'Filtros?')}</div>

                <button type="button" className="header-button" onClick={() => resetFilters(history, data)}>
                  {t('Limpiar', 'Limpiar?')}
                </button>
              </div>
              <Accordion.Collapse eventKey="0">
                <form className="pl-md-3 pr-md-3">
                  <AlquilerFiltersSimple
                    listName={t('Familia', 'Familia?')}
                    list={data.familias.filter((fam: any) => fam.isAlq)}
                    currentValue={data.familia ? data.familia.cod : ''}
                    handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeFamiliaFilter(
                      history, data, e.target.value,
                    )}
                  />
                  <AlquilerFiltersSimple
                    listName={t('Grupo', 'Grupo?')}
                    list={data.familia.grupos.filter((grp: any) => grp.isAlq)}
                    currentValue={data.grupo ? data.grupo.cod : ''}
                    handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeGrupoFilter(
                      history, data, e.target.value,
                    )}
                  />
                  {data.items && (
                    <div className="AlquilerFiltersSimple form-group">
                      <label htmlFor="searchText" className="filter-label">
                        {' ' + t('Refinar búsqueda (texto)', 'Refinar búsqueda (texto)?')}
                      </label>
                      <input
                        type="text"
                        name="searchText"
                        className="form-control filter-input"
                        value={data.searchText || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeTextFilter(
                          history, data, e.target.value,
                        )}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                      />
                    </div>
                  )}

                  {data.rangeFilters.map((filter: any, idx: number) => (
                    <AlquilerFiltersRange key={idx} data={data} filter={filter} />
                  ))}
                </form>
              </Accordion.Collapse>
            </div>
          </div>
        </div>
      </Accordion>
    </>
  );
}
function toggleFilterText(t: TFunction<string>, filterText: string) {
  return filterText === t('Filtros', 'Filtros?')
    ? t('Mostrar', 'Mostrar?') + ' ' + t('Filtros', 'Filtros?').toLowerCase()
    : t('Filtros', 'Filtros?');
}
/*
  Filter handling functions
*/
export function resetFilters(history: History<unknown>, data: any) {
  history.push(data.basePath as string);
}
export function changeTextFilter(history: History<unknown>, data: any, text: string) {
  if (text) {
    data.urlParams.set('tx', text.toLowerCase());
  } else {
    data.urlParams.delete('tx');
  }
  history.push(buildUrl(data));
}
export function changeFamiliaFilter(history: History<unknown>, data: any, cod: string) {
  const familia2 = cod ? data.familias.find((f: any) => f.cod === cod) : undefined;
  history.push(data.basePath + (familia2 ? '/' + familia2.urlS : '')
    + (data.searchText ? '?q=' + data.searchText : ''));
}
export function changeGrupoFilter(history: History<unknown>, data: any, cod: string) {
  const { familia } = data;
  const grupo = familia ? familia.grupos.find((g: any) => g.cod === cod) : null;
  history.push(data.basePath
    + (familia ? '/' + familia.urlS + (grupo ? '/' + grupo.urlS : '') : '')
    + (data.searchText ? '?q=' + data.searchText : ''));
}
/**
 * build a URL from urlParams
 */
function buildUrl(data: any) {
  return data.currentPath + '?' + data.urlParams.toString();
}
