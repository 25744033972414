import React from 'react';

export default function HeroPanel({ panel }: {panel: any}) {
  const {
    panelType,
    errorNumber,
    title,
    subtitle,
    copy,
  } = panel;
  return (
    <div className="container">
      <h3>{panelType}: {errorNumber}</h3>
      <h4>{title}</h4>
      <h5>{subtitle}</h5>
      <div>{copy}</div>
    </div>
  );
}
