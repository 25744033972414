import React from 'react';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import { bgImageStyle } from '../../common/utils/utils';

export default function VentaFamilias({ data, t }: {data: any; t: TFunction<string>}) {
  const { familias, basePath }: {familias: any[], basePath: string} = data;
  const query = data.search || '';

  const disponibles = t('Disponibles', 'Disponibles?').toLowerCase();

  return (
    <div className="row">
      {familias.filter((fam) => fam.cantV > 0).map((fam) => (
        <div
          key={fam.cod}
          className="column-item bg--primary-2 mb-4 border--round family-item"
        >
          <Link to={basePath + '/' + fam.urlX + query} className="block w-100">
            <div
              className="mb-0 feature feature-7 boxed text-center imagebg"
              data-overlay="3"
            >
              <div
                className="background-image-holder"
                style={bgImageStyle(fam.img)}
               />
              <h4 className="pos-vertical-center">{fam.nomb}</h4>
            </div>
            <div className="available-label text-center p-2">
              {fam.cantV + ' ' + disponibles}
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
