/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import './IndexPanel.scss';

function renderItem(item: any, idx: number) {
  if (item.id === '-') {
    return (<h4 key={idx}>{item.name}</h4>);
  }
  return (
    <div key={idx} className="item">
      <Link to={item.id} className="h4">{item.name}</Link>
      <div className="item-description">{item.copy}</div>
    </div>
  );
}
export default function HeroPanel({ panel }: {panel: any}) {
  const { body, pages } = panel;
  return (
    <section className="IndexPanel space--0">
      <div className="container">
        <div className="rtf" dangerouslySetInnerHTML={{ __html: body }} />
        <div className="page-list">
          {pages.map((page: any, idx: number) => renderItem(page, idx))}
        </div>
      </div>
    </section>
  );
}
