import React from 'react';
import CtaButton from '../common/components/CtaButton';
import './SimpleBannerPanel.scss';

export default function SimpleBannerPanel({ panel }: {panel: any}) {
  return (
    <section className="SimpleBannerPanel text-center cta cta-4 space--xxs border--bottom" data-overlay="1">
      <div className="container">
        <div className="row">
          <div className="col-md-auto banner-text">
            {panel.title}
          </div>
          <div className="col-md-3 text-md-left">
            <CtaButton cta={panel.cta} bgColor="2" />
          </div>
        </div>
      </div>
    </section>
  );
}
