/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { bgImageStyle } from '../common/utils/utils';
import CtaButton from '../common/components/CtaButton';
import './CardVideoPanel.scss';

export default function CardVideoPanel({ panel }: {panel: any}) {
  const imageClass = `background-image-holder${
    panel.imageResizeType === 'Contain' ? ' background-image-contain'
      : panel.imageResizeType === 'Fill' ? ' background-image-fill'
        : ''}`;

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <section
      className={
        `CardVideoPanel imageblock space--xs${
          panel.hasGrayBackground ? ' hasGrayBackground' : ''}`
      }
    >
      <div className="container">
        <div
          className={
            `cardContent${panel.isImageFlipped ? ' flippedCard' : ''}`
          }
        >
          <div className="col-lg-6 pos-right mobile-height">
            {panel.image && panel.image.url && (
              <div
                className={imageClass + ' video-cover'}
                style={bgImageStyle(panel.image.url)}

              >
                <button type="button" className="video-play-icon" onClick={handleShow} />
              </div>
            )}
          </div>
          <div className="col-lg-5">
            <div className="panel-tagline">{panel.tagline}</div>
            <h3>{panel.title}</h3>
            <div
              className="lead rtf"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: panel.body }}
            />
            <CtaButton cta={panel.cta} />
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="modal-90w CardVideoPanel"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body id="video-container">
          <iframe
            title={panel.title}
            src={`https://player.vimeo.com/video/${panel.videoID}`}
            height="100%"
            frameBorder="0"
            allow="fullscreen; autoplay; encrypted-media"
          />
        </Modal.Body>
      </Modal>
    </section>
  );
}
