import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import type { History } from 'history';
import InputRange from 'react-input-range';
import './AlquilerFiltersRange.scss';
import 'react-input-range/lib/css/index.css';

export default function AlquilerFiltersRange({ data, filter } : { data: any; filter: any }) {

  const history = useHistory();

  const rangeLength = filter.range.length;
  const minValue = 0;
  const maxValue = rangeLength - 1;

  let i;
  let min = minValue;
  if (filter.minSelected) {
    // move forward while greater or equal
    for (i = 1; i < rangeLength && filter.minSelected >= filter.range[i]; i += 1) {
      min += 1;
    }
  }
  let max = maxValue;
  if (filter.maxSelected) {
    // move backwards while less or equal
    for (i = rangeLength - 2; i >= min && filter.maxSelected <= filter.range[i]; i -= 1) {
      max -= 1;
    }
  }
  const [value, setValue] = useState<any>({ min, max });

  useEffect(() => {
    setValue({ min, max });
  }, [min, max]);

  if (value.max > maxValue || value.min < minValue) {
    // prevent render before values are set
    return null;
  }
  return (
    <div className="AlquilerFiltersRange">
      <div className="slider-title">{filter.label}</div>
      <div className="slider-wrapper">
        <InputRange
          formatLabel={(v) => `${filter.range[v]}`}
          step={1}
          draggableTrack={false}
          maxValue={maxValue}
          minValue={minValue}
          value={value}
          onChangeComplete={(v) => changeRangeFilter(history, data, filter, v)}
          onChange={setValue}
        />
      </div>
    </div>
  );
}
function changeRangeFilter(history: History<unknown>, data: any, filter: any, value: any) {
  const minSelected = filter.range[value.min];
  const maxSelected = filter.range[value.max];
  if (minSelected > filter.range[0]) {
    data.urlParams.set(filter.q + '0', minSelected);
  } else {
    data.urlParams.delete(filter.q + '0');
  }
  if (maxSelected < filter.range[filter.range.length - 1]) {
    data.urlParams.set(filter.q + '1', maxSelected);
  } else {
    data.urlParams.delete(filter.q + '1');
  }
  history.push(buildUrl(data));
}
function buildUrl(data: any) {
  return data.currentPath + '?' + data.urlParams.toString();
}
