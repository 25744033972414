/* eslint-disable react/no-array-index-key */
import React from 'react';
import './OfficesPanel.scss';

export default function OfficesPanel({ panel }: {panel: any}) {
  return (
    <section className="OfficesPanel">
      <div className="container">
        <div className="panel-tagline">{panel.tagline}</div>
        <h3 className="panel-title">{panel.title}</h3>
        <div className="offices-container">

          {panel.offices && (
            panel.offices.map((office: any, i: number) => (
              <div key={i} className="office">
                <div className="office__country">{office.country}</div>
                <div className="office__copy">{office.copy}</div>
              </div>

            ))
          )}
        </div>
      </div>
    </section>
  );
}
