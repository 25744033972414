/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  CotizadorContext, closeCotizador, removeItemAlquiler, removeItemVenta, addItemAlquiler, sendForm,
} from './CotizadorContext';
import { bgImageStyle } from '../common/utils/utils';
import ConfirmModal from '../common/components/ConfirmModal';
import './Cotizador.scss';

function Cotizador(props: any) {
  const { langInfo } = props;
  const { siteInfo } = langInfo;
  const [cotizador, cotizadorDispatch] = useContext<any>(CotizadorContext);
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  // state for confirm modal
  const [modalData, setModalData] = useState<any>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  /* contact form info: encoded as
      ?formKey={guid}&title={title}[&isCotiz=1]
    */
  const formInfo = new URLSearchParams(cotizador.formInfo || siteInfo.cotizadorFormLink.substring(1));
  const formUrl = siteInfo.contactsApiUrl + formInfo.get('formKey');
  const title = formInfo.get('title');
  const isCotiz = !!formInfo.get('isCotiz');

  const onSubmit = (data: any) => {
    wait(1000).then(() => {
      if (executeRecaptcha) {
        executeRecaptcha('formContacto')
          .then((token) => {
            data.recaptcha = token;
            sendForm(cotizadorDispatch, formUrl, data);
          });
      }
    });
  };

  const onClose = () => {
    cotizadorDispatch(closeCotizador());
  };

  return (
    <section className="QuoteListPanel p-0">

      <div className="container">
        <div className="panelTopBox">
          <div className="panelHeader">
            <h2 className="m-0">
              {`${t('Contacto', 'Contacto')}: ${title}`}
            </h2>
            <button type="button" className="btn type--uppercase m-0" onClick={onClose}>
              <span className="btn__text">{t('Cerrar', 'Cerrar')}</span>
            </button>
          </div>
          {isCotiz && !cotizador.formSucceed && (
            <div className="panelItems">
              <>
                {cotizador.itemsAlq.length > 0 && cotizador.itemsAlq.map((item: any, idx: number) => (
                  <div key={idx} className="card-item border">
                    <div className="card-image" style={bgImageStyle(item.img || '/img/tepam/items/missing.png')} />
                    <div className="item-data">
                      <span className="item-type">{t('Alquilar', 'Alquilar?')}</span>
                      <div className="item-title">{item.nomb}</div>
                    </div>
                    <div className="item-form">
                      <span>{t('Cantidad', 'Cantidad?')}</span>
                      <input
                        required
                        type="number"
                        min={1}
                        value={item.qty}
                        onChange={(event) => cotizadorDispatch(addItemAlquiler(
                          { ...item, qty: event.target.value }, idx,
                        ))}
                      />
                    </div>

                    <div className="item-remove">
                      <button
                        type="button"
                        title="Eliminar"
                        className="btn btn-danger btn-remove m-0"
                        onClick={() => setModalData({
                          message: item.nomb,
                          actionToDo: removeItemAlquiler(idx),
                        })}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ))}
                {cotizador.itemsVta.length > 0 && cotizador.itemsVta.map((item: any, idx: number) => (
                  <div key={idx} className="card-item border">
                    <div className="card-image" style={bgImageStyle(item.img || '/img/tepam/items/missing.png')} />
                    <div className="item-data">
                      <span className="item-type">{t('Venta', 'Venta?')}</span>
                      <div className="item-title">{item.cod} - {item.mca}{' '}{item.mod}</div>
                    </div>

                    <div className="item-remove">
                      <button
                        type="button"
                        className="btn btn-danger btn-remove m-0"
                        onClick={() => setModalData({
                          message: `${item.cod} - ${item.mca} ${item.mod}`,
                          actionToDo: removeItemVenta(idx),
                        })}
                      >
                        x
                      </button>
                    </div>
                  </div>
                ))}
              </>
            </div>
          )}
          {modalData && (
            <ConfirmModal
              onClickConfirm={() => {
                cotizadorDispatch(modalData.actionToDo);
                setModalData(null);
              }}
              onClickCancel={() => setModalData(null)}
              message={t('SeguroEliminarCotizador', 'Seguro que desea eliminar "{0}" del cotizador?')
                .replace('{0}', modalData.message)}
            />
          )}
        </div>
        <div className="QuoteFormPanel">
          {formUrl && (
            <>
              {cotizador.formSucceed && (
              <div className="confirm-message">
                <h1>{t('Email enviado!', 'Email enviado!?')}</h1>
                <p>{t('Email_enviado_desc', 'Gracias!?')}</p>
                <button
                  type="button"
                  className="btn type--uppercase m-0"
                  onClick={onClose}
                >
                  <span className="btn__text">
                    {t('Volver al sitio', 'Volver al sitio?')}
                  </span>
                </button>
              </div>
              )}
              {!cotizador.formSucceed && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="Nombre">{t('Nombre', 'Nombre?')}</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="Nombre"
                      ref={register({
                        required: true,
                      })}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="Email">{t('Email', 'Email?')}</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="Email"
                      ref={register({
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group  col-md-6">
                    <label htmlFor="Empresa">{t('Empresa', 'Empresa?')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="Empresa"
                      ref={register}
                    />
                  </div>
                  <div className="form-group  col-md-6">
                    <label htmlFor="Ubicacion">{t('Ubicación (país/localidad)', 'Ubicación (país/localidad)?')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="Ubicacion"
                      ref={register}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group  col-md-6">
                    <label htmlFor="Telefono">{t('Telefono', 'Telefono?')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="Telefono"
                      ref={register}
                    />
                  </div>
                  {isCotiz && cotizador.itemsAlq.length > 0 && (
                  <div className="form-group col-md-6">
                    <label htmlFor="Plazo">{t('Plazo_estimado', 'Plazo estimado (meses) - para alquiler?')}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="Plazo"
                      ref={register}
                    />
                  </div>
                  )}
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="Comentarios">{t('Comentarios', 'Comentarios?')}</label>
                    <textarea
                      className="form-control"
                      name="Comentarios"
                      rows={5}
                      ref={register}
                    />
                  </div>
                </div>
                {cotizador.formError && (
                <div className="alert alert-danger" role="alert">{cotizador.formError}</div>
                )}
                <div className="row text-center justify-content-center p-3">
                  <button
                    type="submit"
                    className="btn btn--primary-1 submit-button type--uppercase"
                    disabled={cotizador.isSendingForm as boolean}
                  >
                    <span className="btn__text">{cotizador.isSendingForm
                      ? t('Enviando', 'Enviando') + '...' : t('Enviar', 'Enviar')}
                    </span>
                  </button>
                </div>

                {/* Add Items for rent and sell  */}
                {cotizador && cotizador.itemsAlq.map((item: any, idx: number) => (
                  <input
                    key={idx}
                    type="hidden"
                    name={`alq-${idx + 1}`}
                    value={JSON.stringify(item)}
                    ref={register}
                  />
                ))}
                {cotizador && cotizador.itemsVta.map((item: any, idx: number) => (
                  <input
                    key={idx}
                    type="hidden"
                    name={`vta-${idx + 1}`}
                    value={JSON.stringify(item)}
                    ref={register}
                  />
                ))}
              </form>
              )}
            </>
          )}
          {!formUrl && <div>Form selection is missing.</div>}
        </div>
      </div>
    </section>
  );
}

export default Cotizador;
function wait(msec: number) { return new Promise((resolve) => setTimeout(resolve, msec)); }
