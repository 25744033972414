import React from 'react';
import SimpleHeaderBar from '../common/components/SimpleHeaderBar';
import Breadcrumb from '../common/components/Breadcrumb';

export default function SimpleHeroPanel({ pageInfo, panel }: {pageInfo: any; panel: any}) {
  const title = panel.title ? panel.title : pageInfo.pageTitle;

  return (
    <>
      <SimpleHeaderBar title={title} />
      <Breadcrumb urls={pageInfo.bcUrls} names={pageInfo.bcNames} />
    </>
  );
}
