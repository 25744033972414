/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import { SiteContext, SiteContextValue } from '../site/SiteContext';
import { bgImageStyle, mediaUrl } from '../common/utils/utils';
import { sendGAdsConversion } from '../common/utils/gtagHooks';
import Breadcrumb from '../common/components/Breadcrumb';
import SimpleHeaderBar from '../common/components/SimpleHeaderBar';
import NoHeaderBar from '../common/components/NoHeaderBar';
import { CotizadorContext, addItemVenta } from '../cotizador/CotizadorContext';
import { txLoading } from '../common/utils/preTranslate';

import './VentaItemPanel.scss';

export default function VentaItemPanel({ panel }: {panel: any}) {

  const { t } = useTranslation();
  const [, cotizadorDispatch] = useContext<any>(CotizadorContext);

  const [showZoomedImage, setshowZoomedImage] = useState(false);
  const [currentIndexImage, setcurrentIndexImage] = useState(0);

  const handleZoom = (show: boolean) => {
    setshowZoomedImage(show);
    document.body.style.overflow = show ? 'hidden' : 'unset';
    if (show) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  };
  const history = useHistory();

  const [showVideo, setShowVideo] = useState(false);
  const handleShowVideo = () => setShowVideo(true);
  const handleCloseVideo = () => setShowVideo(false);

  const { lang, infos, errors, dispatch } = useContext<SiteContextValue | undefined>(SiteContext) as SiteContextValue;
  const langInfo = infos['/site/langInfo'];
  const { siteInfo } = langInfo;

  const { pathname, search } = useLocation();

  const segm = pathname.split('/').filter((x) => x);
  let codInv = segm[segm.length - 1].split('--')[0];

  if (!codInv || codInv.length <= 3) {
    codInv = 'not-found';
  }
  const path = `/itemsVta/${codInv.substring(0, codInv.length - 3)}/eqp${codInv}`;
  const isCustomListing = search.includes('isCL');

  // on mount: request the item info
  useEffect(() => {
    dispatch({ type: 'ADD_REQUEST', path });
  }, [path, dispatch]);

  const handleAddCotizador = (item: any) => {
    // ------------ dispatch with GAds Conversion -----------------------
    sendGAdsConversion(() => cotizadorDispatch(addItemVenta(item)), siteInfo.env.isProd);
  };

  const item = infos[path];
  const itemError = errors[path];
  if (!item) { // loading or error
    return (
      <>
        <NoHeaderBar />
        <div className="container">
          {itemError ? (
            <div className="text-danger">{itemError}</div>
          ) : (
            <div className="text-info">{txLoading(lang)}...</div>
          )}
        </div>
      </>
    );
  }
  const nomb = `${item.mca} ${item.mod}`;

  if (!item.imgs) {
    item.imgs = [
      '/img/tepam/items/missing.png',
      '/img/tepam/items/missing.png',
      '/img/tepam/items/missing.png',
    ];
  }
  return (
    <div>
      <Helmet>
        <title>Tepam Rental - {nomb}</title>
        {item.desc && (<meta name="description" content={item.desc.replace(/<[^>]*>/g, '')} />)}
      </Helmet>
      <SimpleHeaderBar title={nomb} />
      <Breadcrumb urls={item.bcUrls} names={item.bcNames} />
      <div className="VentaItemPanel pt-3 pb-3">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-7 col-lg-6">
              {item.imgs && item.imgs.length > 0 && (
                <Carousel>
                  {item.imgs.map((img: any, i: number) => (
                    <Carousel.Item key={i} onClick={() => { handleZoom(true); setcurrentIndexImage(i); }}>
                      <div
                        className="slider-item-image wf-gray-background"
                        style={bgImageStyle(img)}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </div>

            <div className="col-md-5 col-lg-5 pt-4 pt-md-0 ml-lg-5 mr-auto">
              {item.desc && (<div className="rtf" dangerouslySetInnerHTML={{ __html: item.desc }} />)}
              <div className="row font-weight-bold pt-3 row">
                <ul className="specification-list col">
                  {item.an && (
                    <li className="item-specification">
                      {t('Año', 'Año?')}: {item.an}
                    </li>
                  )}
                  {item.km && (
                    <li className="item-specification">Km: {item.km}</li>
                  )}
                  {item.hs && (
                    <li className="item-specification">
                      {t('Horas', 'Horas')}: {item.hs}
                    </li>
                  )}
                  {item.est && (
                    <li className="item-specification">
                      {t('Estado', 'Estado?')}: {item.est}
                    </li>
                  )}
                  {item.pais && (
                    <li className="item-specification">
                      {t('País', 'País?')}: {item.pais}
                    </li>
                  )}
                </ul>
              </div>
              <div className="row mt-2">
                {isCustomListing && (
                  <button
                    type="button"
                    className="btn btn--primary-2 btn--cotizador col my-3 mr-1 p-3"
                    onClick={() => history.goBack()}
                  >
                    <span className="btn__text">{t('Volver')}</span>
                  </button>
                )}
                {!isCustomListing && (
                  <button
                    type="button"
                    className="btn btn--primary-2 btn--cotizador col my-3 mr-1 p-3"
                    onClick={() => handleAddCotizador(item)}
                  >
                    <span className="btn__text">{panel.buttonCopy}</span>
                  </button>
                )}
                {item.videoID && (
                <button
                  type="button"
                  className="btn btn--primary-1 btn--cotizador col my-3 ml-1 p-3"
                  onClick={() => handleShowVideo()}
                >
                  <span className="btn__text">{panel.videoCopy}</span>
                </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {(item.files || item.desc) && (
          <div className=" space--sm container pt-5 pb-5">
            <div className="container">
              <div className="row justify-content-center w-100">
                <Tabs
                  className="w-100 "
                  defaultActiveKey={t('Especificaciones', 'Especificaciones?')}
                  id="uncontrolled-tab-example"
                >
                  <Tab
                    eventKey={t('Especificaciones', 'Especificaciones?')}
                    title={t('Especificaciones', 'Especificaciones?')}
                  >
                    {item.desc && (
                      <div
                        className="p-4"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: item.desc }}
                      />
                    )}
                  </Tab>
                  {item.files && (
                    <Tab
                      eventKey={t('Descargas', 'Descargas')}
                      title={t('Descargas', 'Descargas')}
                    >
                      <div className="p-4">
                        <div className="files-container mt-md-4">
                          <ListGroup variant="flush">
                            {item.files.map((file: any, i: number) => (
                              <a key={i} href={mediaUrl(file.url)} target="_blank" rel="noopener noreferrer">
                                <ListGroup.Item className="p-1 pl-3">
                                  {file.name}
                                  <i className="icon-Download font-weight-bolder p-2" />
                                </ListGroup.Item>
                              </a>
                            ))}
                          </ListGroup>
                        </div>
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        )}

        {showZoomedImage && (
          <div className="zoomed-image">
            <img
              className="d-block m-auto"
              src={mediaUrl(item.imgs[currentIndexImage].split(/[?#]/)[0])}
              alt="First slide"
            />
            <button type="button" className="btn btn--xs btn-danger rounded-pill"
              onClick={() => handleZoom(false)}
            >X
            </button>
          </div>
        )}

        {item.videoID && (
        <Modal
          dialogClassName="modal-90w SimpleVideoPanel"
          show={showVideo}
          onHide={handleCloseVideo}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body id="video-container">
            <iframe
              title={panel.title}
              src={`https://player.vimeo.com/video/${item.videoID}`}
              height="100%"
              frameBorder="0"
              allowFullScreen
              allow="fullscreen; autoplay; encrypted-media"
            />
          </Modal.Body>
        </Modal>
        )}
      </div>
    </div>
  );
}
