import React from 'react';
import './Breadcrumb.scss';
import { Link } from 'react-router-dom';

function Breadcrumb(props: any) {
  let { urls, names } = props;

  urls = urls ? urls.split(',') : [];
  names = names ? names.split(',') : [];

  if (urls.length < 2) {
    return null; // do not show if only "Home" in breadcrumb
  }

  // concatenate paths
  let s = '';
  const urls2: string[] = [];
  urls.forEach((u: string) => {
    s = `${s}/${u}`;
    urls2.push(s);
  });

  return (
    <div className="Breadcrumb container">
      <ul>
        <Link to={urls2[0]}><i className="material-icons">home</i></Link>
        {urls2.slice(1).map((url, idx) => (
          <li key={url} className="capitalize"><Link to={url}>{names[idx + 1]}</Link></li>
        ))}
      </ul>
    </div>
  );
}

export default Breadcrumb;
