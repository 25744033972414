/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { TFunction, useTranslation } from 'react-i18next';
import { SiteContext, SiteContextValue } from '../site/SiteContext';
import { toLowerAscii } from '../common/utils/utils';
import SimpleHeaderBar from '../common/components/SimpleHeaderBar';
import BigHeroBlock from '../common/components/BigHeroBlock';
import Breadcrumb from '../common/components/Breadcrumb';
import SearchBox from '../search/SearchBox';
import VentaFamilias from './venta/VentaFamilias';
import VentaGrupos from './venta/VentaGrupos';
import VentaFilters from './venta/VentaFilters';
import VentaItems from './venta/VentaItems';
import './VentaPanel.scss';

const maxItems = 6; // if more items than this and no group selected, show grp selection

export default function VentaPanel({ langInfo, pageInfo, panel }: {langInfo: any, pageInfo: any, panel: any}) {

  const { infos, errors, dispatch } = useContext<SiteContextValue | undefined>(SiteContext) as SiteContextValue;

  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const pathSegm = pathname.split('/').filter((s) => s);

  // on mount: request the full search info
  useEffect(() => {
    dispatch({ type: 'ADD_REQUEST', path: '/site/searchVtaEqp' });
  }, [dispatch]);

  const { searchTree } = langInfo;
  const searchVtaEqp = infos['/site/searchVtaEqp'];
  const searchVtaEqpError = errors['/site/searchVtaEqp'];

  // get filters from URL, apply to available items
  const data = applyFilters(pathSegm, search, panel, searchTree, searchVtaEqp, pageInfo, t);

  return (
    <>
      <Helmet>
        <title>Tepam Rental - {data.pageTitle}</title>
        <meta name="description" content={data.metaDescription} />
      </Helmet>
      {data.familia
        ? (
          <>
            <SimpleHeaderBar title={data.pageTitle} />
            <Breadcrumb urls={data.bcUrls} names={data.bcNames} />
          </>
        ) : (
          <BigHeroBlock {...{ ...panel }} />
        )}
      <div className={`VentaPanel has-filters space--sm px-md-5 py-0 ${data.familia ? '' : 'mt-5'}`}>
        <div className="container">
          <div className="row pb-3">
            <div className="col-12 col-md-7 offset-md-5 col-lg-9 offset-lg-3">
              <SearchBox langInfo={langInfo} showAV="V" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-5 col-lg-3">
              <VentaFilters data={data} />
            </div>
            {data.items ? (
              <div className="col-12 col-md-7 col-lg-9">
                <VentaItems panel={panel} data={data} />
              </div>
            ) : !searchVtaEqp ? (
              <div className="container">
                {searchVtaEqpError ? (
                  <div className="text-danger">{searchVtaEqpError}</div>
                ) : (
                  <div className="text-info">{t('Cargando', 'Cargando')}...</div>
                )}
              </div>
            ) : (
              <div className="col-12 col-md-7 col-lg-9">
                {data.familia
                  ? (
                    <VentaGrupos data={data} t={t} />
                  )
                  : (
                    <VentaFamilias data={data} t={t} />
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// function applyFilters(pathSegm, search, panel, searchTree, searchVtaEqp, pageInfo, t) {

function applyFilters(pathSegm: string[], search: string, panel: any, searchTree: any,
  searchVtaEqp: any, pageInfo: any, t: TFunction<string>): any {
  // initialize values
  const basePath = `/${pathSegm.slice(0, 2).join('/')}`;
  let familia: any;
  let grupo: any;
  let categ: any;
  let items: any;

  // preassign data properties
  const data : any = {
    pageTitle: pageInfo.pageTitle,
    metaDescription: pageInfo.metaDescription,
    bcUrls: pageInfo.bcUrls,
    bcNames: pageInfo.bcNames,
    basePath,
    currentPath: basePath,
    search,
    urlParams: new URLSearchParams(search),
    familias: searchTree,
    paises: panel.paises,
    selectedMarcas: [],
    selectedModelos: [],
    rangeFilters: [],
  };

  // if filters and search data is not available, return ("Loading...")
  if (search && !searchVtaEqp) {
    return data;
  }

  // check selection for country
  const pais = data.pais = data.urlParams.get('pais');

  // verify if there is an explicit family selection: 'familia'
  familia = data.familia = pathSegm.length < 3 ? undefined
    : data.familias.find((g :any) => g.urlS === pathSegm[2]);

  if (familia) {
    // change breadcrumb, title and url for an additional level: family
    data.bcUrls = `${data.bcUrls},${pageInfo.urlSegm}`;
    data.bcNames = `${data.bcNames},${data.pageTitle}`;
    data.currentPath += `/${familia.urlS}`;
    data.pageTitle = `${pageInfo.pageTitle}: ${familia.nomb}`;
    data.metaDescription = data.pageTitle;

    // verify if there is an explicit or implicit group selection
    const urlSegmGrupo = pathSegm.length < 4 ? undefined : pathSegm[3];
    grupo = data.grupo = urlSegmGrupo ? familia.grupos.find((g: any) => g.urlS === urlSegmGrupo)
      : familia.grupos.length === 1 ? familia.grupos[0] : undefined;

    // apply grupo filter
    if (grupo) {
      // change breadcrumb, title and url for an additional level: group
      data.bcUrls = `${data.bcUrls},${familia.urlS}`;
      data.bcNames = `${data.bcNames},${familia.nomb}`;
      data.currentPath += `/${grupo.urlS}`;
      data.pageTitle = `${pageInfo.pageTitle}: ${grupo.nomb}`;
      data.metaDescription = data.pageTitle;

      // verify if there is an explicit or implicit category selection
      const urlSegmCateg = pathSegm.length < 5 ? undefined : pathSegm[4];
      categ = data.categ = urlSegmCateg ? grupo.categs.find((c :any) => c.urlS === urlSegmCateg)
        : grupo.categs.length === 1 ? grupo.categs[0] : undefined;

      if (categ) {
        // change breadcrumb, title and url for an additional level: group
        data.bcUrls = `${data.bcUrls},${grupo.urlS}`;
        data.bcNames = `${data.bcNames},${grupo.nomb}`;
        data.currentPath += `/${categ.urlS}`;
        data.pageTitle = `${pageInfo.pageTitle}: ${categ.nomb}`;

        // initialize items collection for a categ
        items = pais ? [] : categ.equipos;
      } else {
        // initialize items collection for a group
        items = pais ? [] : grupo.categs.filter((c :any) => c.equipos)
          .flatMap((x :any) => x.equipos);
      }
    } else {
      items = pais ? [] : familia.grupos.flatMap((x :any) => x.categs).filter((c :any) => c.equipos)
        .flatMap((x :any) => x.equipos);
    }

    if (!searchVtaEqp) {
      // not loaded yet - and no query string either
      return data;
    }
  }

  // apply selection for country
  if (pais) {
    // rebuild F-G-C tree for the current country
    items = [];
    const fams: any[] = [];
    data.familias.forEach((fam :any) => {
      const fam2 = {
        ...fam, grupos: [], cantV: 0, items: [],
      };
      fam.grupos.forEach((grp :any) => {
        const grp2 = { ...grp, categs: [], cantV: 0 };
        grp.categs.forEach((cat :any) => {
          const eqps = cat.equipos ? cat.equipos.filter((i: any) => i.pais === pais) : [];
          const cantV = eqps.length;
          if (cantV > 0 || (categ && categ.cod === cat.cod)) {
            grp2.categs.push(cat);
            if (cantV > 0) {
              grp2.cantV += cantV;
              if (!familia || (familia.cod === fam.cod
                && (!grupo || (grupo.cod === grp.cod
                  && (!categ || categ.cod === cat.cod))))) { items.push(...eqps); }
            }
          }
        });
        if (grp2.cantV > 0 || (grupo && grupo.cod === grp.cod)) {
          fam2.grupos.push(grp2);
          fam2.cantV += grp2.cantV;
        }
      });
      if (fam2.cantV > 0 || (familia && familia.cod === fam.cod)) {
        fams.push(fam2);
      }
    });

    // rebuild lists for F-G-C selectors
    data.familias = fams;
    if (familia) {
      familia = data.familia = data.familias.find((f :any) => f.cod === familia.cod);
      if (familia) {
        data.grupos = familia.grupos;
        if (grupo) {
          grupo = data.grupo = data.grupos.find((g :any) => g.cod === grupo.cod);
          if (grupo) {
            data.categs = grupo.categs;
            if (categ) {
              categ = data.categ = data.categs.find((c :any) => c.cod === categ.cod);
            }
          }
        }
      }
    }
  }

  if (familia) {
    // check selection for marca and model
    const mcas = data.urlParams.get('marca');
    if (mcas) {
      data.selectedMarcas = mcas.split('--');

      const mods = data.urlParams.get('modelo');
      if (mods) {
        data.selectedModelos = mods.split('--');
      }
    }

    // restrict marca/modelo options by available items
    data.marcas = panel.marcas.filter((m :any) => items.some((i :any) => i.mca === m.nomb));
    if (data.selectedMarcas.length === 1) {
      const mca1 = data.marcas.find((m :any) => m.nomb === data.selectedMarcas[0]);
      if (mca1) {
        data.modelos = mca1.modelos.filter((m :any) => items.some((i :any) => i.mod === m));
      }
    }

    // apply marca / modelo filters
    if (data.selectedMarcas.length > 0) {
      if (data.selectedMarcas.length === 1) {
        const mca = data.selectedMarcas[0];
        if (data.selectedModelos.length === 0) {
          items = items.filter((i :any) => i.mca === mca);
        } else if (data.selectedModelos.length === 1) {
          const mod = data.selectedModelos[0];
          items = items.filter((i :any) => i.mca === mca && i.mod === mod);
        } else {
          items = items.filter((i :any) => i.mca === mca && data.selectedModelos.some((s :any) => s === i.mod));
        }
      } else {
        items = items.filter((i :any) => data.selectedMarcas.some((s :any) => s === i.mca));
      }
    }
  }

  if (!familia
    || (!grupo && familia.grupos.length > 1
      && familia.cantV > maxItems
      && data.selectedMarcas.length === 0)) {
    // no family+group selected, and more than a few items: go select a family/group
    return data;
  }

  if (!searchVtaEqp) {
    // not loaded yet
    return data;
  }

  // check if there is an additional text selection, filter on that
  data.searchText = (data.urlParams.get('tx') || '');
  const tx = data.searchText.length >= 3 ? data.searchText : undefined;
  if (tx) {
    const words = toLowerAscii(tx).split(' ').filter((w) => w && w.length >= 3);
    words.forEach((word) => {
      items = items.filter((i: any) => i.kwds.includes(word));
    });
  }

  // compute range filters, based on all items selected so far
  // then apply to items collection
  const items1 = items;
  items = addRangeFilter(data, items1, items, 'an', t('Año', 'Año?'),
    (i: any) => i.an, new Date().getFullYear());
  items = addRangeFilter(data, items1, items, 'km', t('Uso (miles km)', 'Uso (miles km)?'),
    (i: any) => Math.round(i.km / 1000));
  items = addRangeFilter(data, items1, items, 'hs', t('Uso (horas)', 'Uso (horas)?'),
    (i: any) => i.hs);

  // assign filtered items in data
  data.items = items;

  return data;
}

/**
 * Compute a selectable range with a small number of intervals for the specified value array
 * prepare the filter definition (push to array)
 * and apply the filter to the current items
 *
 * @param {*} arr - array of unique values
 */

/**
 * Add and apply a range filter
 */
function addRangeFilter(data: any, items1: any[], items: any[],
  q: string, label: string, filterExpr: any, maxAllowed: number = 0) {
  const range = computeRange(items1.map(filterExpr), maxAllowed);
  if (range) {
    let minV = data.urlParams.get(`${q}0`);
    const minSelected = minV && !Number.isNaN(minV = parseFloat(minV)) ? minV : undefined;

    let maxV = data.urlParams.get(`${q}1`);
    const maxSelected = maxV && !Number.isNaN(maxV = parseFloat(maxV)) ? maxV : undefined;

    data.rangeFilters.push({
      q, label, range, minSelected, maxSelected,
    });

    if (minSelected) {
      if (maxSelected) {
        // both filters
        items = items.filter((x) => filterExpr(x) >= minSelected && filterExpr(x) <= maxSelected);
      } else {
        // only min filter
        items = items.filter((x) => filterExpr(x) >= minSelected);
      }
    } else if (maxSelected) {
      // only max filter
      items = items.filter((x) => filterExpr(x) <= maxSelected);
    }
  }
  return items;
}

function computeRange(allValues: number[], maxAllowed: number) {
  // find unique, not undefined values
  const distinctValues = (new Set(allValues.filter((i) => !Number.isNaN(i))));
  let arr = [...distinctValues];

  if (arr.length < 3) {
    return undefined; // too few values, cannot select
  }
  arr = arr.sort((a, b) => a - b);
  // few elements: return all of them
  if (arr.length < 8) {
    return arr;
  }
  // many elements: compute intervals
  const min = arr[0];
  const max = arr[arr.length - 1];
  const exp = 10 ** (Math.round(Math.log10(max - min)) - 1);
  const interval = Math.ceil((Math.round((max - min) / exp) * exp) / 6);
  const min2 = Math.floor(min / interval) * interval;
  const max2 = Math.ceil(max / interval) * interval;

  const range = [];
  for (let n = min2; n <= max2; n += interval) {
    range.push(n);
  }
  if (maxAllowed && range[range.length - 1] > maxAllowed) {
    range[range.length - 1] = maxAllowed;
  }

  return range;
}
