/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CotizadorContext, openCotizador } from '../../cotizador/CotizadorContext';

/**
 * Button component with multiple functions depending on cta.url :
 *  "/page"     renders a Link
 *  "$?..."     renders a button that triggers an openCotizador
 *  "http://.." renders a regular link to a different site
 */
export default function CtaButton({ cta, bgColor = undefined, children = undefined }:
  {cta: any, bgColor?: string, children?: any}) {
  const [, cotizadorDispatch] = useContext<any>(CotizadorContext);

  if (!cta || !cta.url || !(cta.copy || children)) {
    return null; // do not render
  }
  // default color: primary-1
  const className = `CtaButton btn type--uppercase ${
    bgColor === '2' ? 'btn--primary-2' : 'btn--primary-1'}`;
  const { url } = cta;

  if (url.startsWith('$')) {
    // open contact / cotizador dialog
    const formInfo = url.substring(1);
    return (
      <button type="button" className={className} onClick={() => cotizadorDispatch(openCotizador(formInfo))}>
        {children || (<span className="btn__text mr-0">{cta.copy}</span>)}
      </button>
    );
  }

  if (url.startsWith('/') && !url.startsWith('/media')) {
    // link to page in same site
    return (
      <Link className={className} to={url}>
        {children || (<span className="btn__text mr-0">{cta.copy}</span>)}
      </Link>
    );
  }

  // link to external site or media file (i.e PDF) in new tab
  return (
    <a className={className} href={url} target="_blank" rel="noopener noreferrer">
      {children || (<span className="btn__text mr-0">{cta.copy}</span>)}
    </a>
  );
}
