import { useEffect } from 'react';
import { useLocation } from 'react-router';

/*
gtagHooks : custom hooks to initialize and invoke the 'gtag.js'
   (Google Site Tags: single entry point for GA, GTM, Google Ads, etc)

 - this code uses no additional package
 - it does not invoke 'analytics.js' (as react-ga does) but the newer 'gtag.js'
 - the initialization is done on the first request to usePageView

 2021-05-28 - josem@tercerplaneta.com
*/

export const usePageView = (isProd: boolean) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!isProd) { return; }

    // send the page view event
    (window as any).gtag('event', 'page_view', {
      page_path: pathname + search,
      // send_to: 'UA-174289679-1',
    });

  }, [pathname, search, isProd]);
};

export const sendGAdsConversion = (callback: () => void, isProd: boolean) => {
  if (!isProd) {
    callback();
  } else {
    // send the page view event
    (window as any).gtag('event', 'conversion', {
      send_to: 'AW-397837783/xgygCOz_xoACENeL2r0B',
      event_callback: callback,
    });
  }
};

// function gtag_report_conversion(url) {
//   const callback = function () {
//     if (typeof (url) !== 'undefined') {
//       window.location = url;
//     }
//   };
//   gtag('event', 'conversion', { send_to: 'AW-397837783/xgygCOz_xoACENeL2r0B', event_callback: callback });
//   return false;
// }
