import React, {
  useState, useEffect, useContext, useCallback, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AutoSuggest from 'react-autosuggest';
import { SiteContext, SiteContextValue } from '../site/SiteContext';
import search from './search';
import './SearchBox.scss';

// eslint-disable-next-line react/require-default-props
export default function SearchBox(props: {langInfo: any, showAV?: string | undefined}) {
  const { langInfo } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const showAV = props.showAV || 'AV';
  const isGlobal = showAV.length === 2;
  const history = useHistory();
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const { infos, dispatch } = useContext<SiteContextValue | undefined>(SiteContext) as SiteContextValue;

  const { siteInfo, searchTree } = langInfo;
  const searchVtaEqp = infos['/site/searchVtaEqp'];

  // set focus on search box
  const searchRef = useRef<HTMLInputElement>(null);

  const onSuggestionsFetchRequested = useCallback(
    () => {
      setSuggestions(
        search(t, siteInfo, searchTree, searchVtaEqp, showAV, value),
      );
    },
    [t, siteInfo, searchTree, searchVtaEqp, showAV, value],
  );

  useEffect(() => {
    if (!isGlobal && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isGlobal, searchRef]);

  useEffect(() => {
    if (value && !searchVtaEqp) {
      dispatch({ type: 'ADD_REQUEST', path: '/site/searchVtaEqp' });
    } else if (searchVtaEqp) {
      onSuggestionsFetchRequested();
    }
  }, [
    value,
    t,
    siteInfo,
    searchTree,
    searchVtaEqp,
    showAV,
    dispatch,
    onSuggestionsFetchRequested,
  ]);

  // prepare input props
  const inputProps = {
    placeholder: `${t('Buscar', 'Buscar?')} ${
      (showAV === 'A' ? t('Equipos en alquiler', 'Equipos en alquiler?')
        : showAV === 'V' ? t('Equipos en venta', 'Equipos en venta?')
          : t('Equipos', 'Equipos?')
      ).toLowerCase()}`,
    value,
    onChange,
    ref: undefined as React.RefObject<HTMLInputElement> | undefined,
  };
  if (!isGlobal) {
    inputProps.ref = searchRef;
  }

  return (
    <div
      className={
        isGlobal ? 'SearchBox SearchBox-top' : 'SearchBox SearchBox-panel'
      }
    >
      <AutoSuggest
        multiSection={showAV === 'AV'}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={inputProps}
      />
    </div>
  );

  function onChange(event: any, { newValue, method }: {newValue: any; method: string}) {
    if (method === 'type' || method === 'enter' || method === 'click') {
      setValue(newValue);
    }
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
    setValue('');
  }

  function onSuggestionSelected(event: any, { suggestion }: {suggestion: any}) {
    setValue(value);
    history.push(
      suggestion.url + (suggestion.tx ? `?tx=${suggestion.tx}` : ''),
    );
  }
}

function getSuggestionValue(suggestion: any) {
  return suggestion.name;
}

function renderSuggestion(suggestion: any) {
  return <span>&nbsp;&nbsp;{suggestion.name}</span>;
}

function renderSectionTitle(section: any) {
  return <strong>{section.sectionTitle}</strong>;
}

function getSectionSuggestions(section: any) {
  return section.sectionOptions;
}
