import React from 'react';

export type SiteReducerAction =
| { type: 'ADD_REQUEST'; path: string }
| { type: 'CHANGE_LANG'; lang: string }
| { type: 'FETCH_REQUEST'; path: string }
| { type: 'FETCH_SUCCESS'; payload: {path: string; data: object} }
| { type: 'FETCH_FAILURE'; payload: {path: string; message: string} }

export interface SiteContextValue {
  lang: string;
  infos: object;
  errors: object;
  dispatch: React.Dispatch<SiteReducerAction>;
}

export const SiteContext = React.createContext<SiteContextValue | undefined>(undefined);
