/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import { toLowerAscii } from '../common/utils/utils';
import SimpleHeaderBar from '../common/components/SimpleHeaderBar';
import BigHeroBlock from '../common/components/BigHeroBlock';
import Breadcrumb from '../common/components/Breadcrumb';
import SearchBox from '../search/SearchBox';
import AlquilerFamilias from './alquiler/AlquilerFamilias';
import AlquilerGrupos from './alquiler/AlquilerGrupos';
import AlquilerFilters from './alquiler/AlquilerFilters';
import AlquilerItems from './alquiler/AlquilerItems';
import './AlquilerPanel.scss';

export default function AlquilerPanel({ langInfo, pageInfo, panel }
  : { langInfo: any; pageInfo: any, panel: any }) {

  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const pathSegm = pathname.split('/').filter((s) => s);

  const { searchTree } = langInfo;

  // get filters from URL, apply to available items
  const data = applyFilters(pathSegm, search, searchTree, pageInfo, t);

  const rightSectionClass = data.familia ? 'col-12 col-md-7 col-lg-9' : 'col';
  const searchBoxClass = data.familia ? 'col-12 col-md-7 offset-md-5 col-lg-9 offset-lg-3' : 'col';
  return (
    <>
      <Helmet>
        <title>Tepam Rental - {data.pageTitle}</title>
        <meta name="description" content={data.metaDescription} />
      </Helmet>
      {data.familia
        ? (
          <>
            <SimpleHeaderBar title={data.pageTitle} />
            <Breadcrumb urls={data.bcUrls} names={data.bcNames} />
          </>
        ) : (
          <BigHeroBlock {...{ ...panel }} />
        )}
      <div className={'AlquilerPanel has-filters space--sm px-md-5 py-0 ' + (data.familia ? '' : 'mt-5')}>
        <div className="container">
          <div className="row pb-3">
            <div className={searchBoxClass}>
              <SearchBox langInfo={langInfo} showAV="A" />
            </div>
          </div>
          <div className="row">
            {data.familia && (
              <div className="col-12 col-md-5 col-lg-3">
                <AlquilerFilters data={data} />
              </div>
            )}
            {data.items ? (
              <div className={rightSectionClass}>
                <AlquilerItems panel={panel} data={data} />
              </div>
            ) : (
              <div className={rightSectionClass}>
                {data.familia
                  ? (
                    <AlquilerGrupos grupos={data.familia.grupos} basePath={data.basePath} />
                  )
                  : (
                    <AlquilerFamilias familias={data.familias} basePath={data.basePath} />
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function applyFilters(pathSegm: string[], search: string, searchTree: any, pageInfo: any, t: TFunction<string>): any {
  // initialize values
  const basePath = '/' + pathSegm.slice(0, 2).join('/');
  let grupo;
  let items: any[] = [];

  // preassign data properties
  const data: any = {
    pageTitle: pageInfo.pageTitle,
    metaDescription: pageInfo.metaDescription,
    bcUrls: pageInfo.bcUrls,
    bcNames: pageInfo.bcNames,
    basePath,
    currentPath: basePath,
    urlParams: new URLSearchParams(search),
    familias: searchTree,
    rangeFilters: [],
  };

  // verify if there is an explicit family selection: 'familia'
  const familia = data.familia = pathSegm.length < 3 ? undefined
    : data.familias.find((g: any) => g.urlS === pathSegm[2]);

  if (familia) {
    // change breadcrumb, title and url for an additional level: family
    data.bcUrls = data.bcUrls + ',' + pageInfo.urlSegm;
    data.bcNames = data.bcNames + ',' + data.pageTitle;
    data.currentPath += '/' + familia.urlS;
    data.pageTitle = pageInfo.pageTitle + ': ' + familia.nomb;
    data.metaDescription = data.pageTitle;

    // verify if there is an explicit group selection: 'grupo'
    const urlSegmGrupo = pathSegm.length < 4 ? undefined : pathSegm[3];
    grupo = data.grupo = urlSegmGrupo ? familia.grupos.find((g: any) => g.urlS === urlSegmGrupo)
      : familia.grupos.length === 1 ? familia.grupos[0] : undefined;

    // apply grupo filter
    if (grupo) {
      items = grupo.categs.filter((c: any) => c.isAlq);

      // change breadcrumb, title and url for an additional level: group
      data.bcUrls = data.bcUrls + ',' + familia.urlS;
      data.bcNames = data.bcNames + ',' + familia.nomb;
      data.currentPath += '/' + grupo.urlS;
      data.pageTitle = pageInfo.pageTitle + ': ' + grupo.nomb;
      data.metaDescription = data.pageTitle;

    } else {
      items = familia.grupos.flatMap((x: any) => x.categs).filter((c: any) => c.isAlq);
    }
  }

  if (!grupo) {
    // no family+group selected, and more than one group: go select a family/group
    return data;
  }

  // check if there is an additional text selection, filter on that
  data.searchText = (data.urlParams.get('tx') || '');
  const tx = data.searchText.length >= 3 ? data.searchText : undefined;
  if (tx) {
    const words = toLowerAscii(tx).split(' ').filter((w) => w && w.length >= 3);
    words.forEach((word) => {
      items = items.filter((i) => i.kwds.includes(word));
    });
  }

  // add and apply filters
  items = addRangeFilter(grupo, data, items, 'pt', 'Pot', t('Potencia', 'Potencia?'), 'HP');
  items = addRangeFilter(grupo, data, items, 'po', 'Peso', t('Peso Operativo', 'Peso Operativo?'), 'Tn');
  items = addRangeFilter(grupo, data, items, 'pr', 'Prof', t('Profundidad', 'Profundidad?'), 'm');
  items = addRangeFilter(grupo, data, items, 'ca', 'Cap', t('Capacidad', 'Capacidad?'), grupo.unid);

  // assign filtered items in data
  data.items = items;

  return data;
}

/**
 * Compute a selectable range with a small number of intervals for the specified value array
 * prepare the filter definition (push to array)
 * and apply the filter to the current items
 *
 * @param {*} arr - array of unique values
 */

/**
 * Add and apply a range filter
 */
function addRangeFilter(grupo: any, data: any, items: any[], q: string, alias: string, label: string, unid: string) {
  const range = grupo['rg' + alias];
  if (range && range.length >= 3) {
    let minV = data.urlParams.get(q + '0');
    // eslint-disable-next-line no-cond-assign
    const minSelected = minV && !Number.isNaN((minV = parseFloat(minV))) ? minV : undefined;
    let maxV = data.urlParams.get(q + '1');
    // eslint-disable-next-line no-cond-assign
    const maxSelected = maxV && !Number.isNaN((maxV = parseFloat(maxV))) ? maxV : undefined;
    const minProp = q + '0';
    const maxProp = q + '1';
    if (minSelected) {
      if (maxSelected) {
        // both filters
        items = items.filter((x) => x[maxProp] >= minSelected && x[minProp] <= maxSelected);
      } else {
        // only min filter
        items = items.filter((x) => x[maxProp] >= minSelected);
      }
    } else if (maxSelected) {
      // only max filter
      items = items.filter((x) => x[minProp] && x[minProp] <= maxSelected);
    }
    data.rangeFilters.push({ q, label, range, unid, minSelected, maxSelected });
  }
  return items;
}
