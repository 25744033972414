import React from 'react';
import { Link } from 'react-router-dom';
import { bgImageStyle } from '../../common/utils/utils';

export default function AlquilerFamilias({ familias, basePath }
  :{ familias: any[]; basePath: string }) {

  return (
    <div className="row">
      {familias.filter((fam) => fam.isAlq).map((fam) => (
        <div key={fam.cod} className="column-item bg--primary-2 mb-4 border--round family-item">
          <Link to={basePath + '/' + fam.urlX} className="block w-100">
            <div className="feature feature-7 boxed text-center imagebg" data-overlay="3">
              <div className="background-image-holder"
                style={bgImageStyle(fam.img)}
               />
              <h4 className="pos-vertical-center">{fam.nomb}</h4>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
