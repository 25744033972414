/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { mediaUrl } from '../common/utils/utils';

import './SimpleVideoPanel.scss';

export default function SimpleVideoPanel({ panel }: {panel: any}) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div
      className={
        `SimpleVideoPanel pt-5 pb-5 ${
          panel.hasGrayBackground ? ' hasGrayBackground' : ''}`
      }
    >
      <div className="container">
        <h3>{panel.title}</h3>
        <div className="video-cover border--round">
          <div data-overlay="5">
            <img
              className="responsive"
              alt={panel.image.alt}
              src={mediaUrl(panel.image.url)}
            />
          </div>
          <button type="button" className="video-play-icon" onClick={handleShow} />
        </div>
      </div>

      <Modal
        dialogClassName="modal-90w SimpleVideoPanel"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body id="video-container">
          <iframe
            title={panel.title}
            src={`https://player.vimeo.com/video/${panel.videoID}`}
            height="100%"
            frameBorder="0"
            allowFullScreen
            allow="fullscreen; autoplay; encrypted-media"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
