import React, { useState, useEffect } from 'react';
import { bgImageStyle } from '../common/utils/utils';
import CtaButton from '../common/components/CtaButton';
import './HomeHeroPanel.scss';

export default function HomeHeroPanel(props: any) {
  const { panel } = props;
  const [currentImage, setCurrentImage] = useState('');
  useEffect(() => {
    setCurrentImage(panel.images[Math.floor(Math.random() * panel.images.length)].image.url);
  }, [panel.images]);

  return (
    <section
      className="HomeHeroPanel cover height-home imagebg text-center section--ken-burns"
      data-overlay="5"
    >
      <div
        className="background-image-holder"
        style={bgImageStyle(currentImage)}
       />
      <div className="container">
        <div className="row pt-3">
          <div className="col-md-8 col-lg-8">
            <h1> {panel.title}</h1>
            <p className="lead">{panel.description} </p>
          </div>
        </div>
        <div className="row pt-5 home-buttons">
          <div className="col-md-3 col-lg-2">
            <CtaButton cta={panel.primaryButton} />
          </div>
          <div className="col-md-3 col-lg-2">
            <CtaButton cta={panel.secondaryButton} />
          </div>
        </div>
      </div>
    </section>
  );
}
