/* eslint-disable react/require-default-props */
import React, { useReducer, useEffect, useMemo } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { SiteContext, SiteReducerAction } from './SiteContext';
import Page from '../page/Page';
import { txLoading } from '../common/utils/preTranslate';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';

export interface PreState {
  lang: string;
  infos: object;
}

interface SiteProps {
  lang: string;
  // eslint-disable-next-line react/no-unused-prop-types
  preState?: PreState;
}

interface SiteState {
  lang: string;
  requested: string[];
  inProcess: string[];
  infos: object;
  errors: object;
}

const emptyState: SiteState = {
  lang: '',
  requested: [],
  inProcess: [],
  infos: {},
  errors: {},
};

/**
 * Site component - stateful, fetches language/site data as required
 */
export default function Site(props: SiteProps) {
  const { lang } = props;
  const [state, dispatch] = useReducer(siteContextReducer, props, initState);
  const { infos, errors } = state;
  // translation
  const { i18n } = useTranslation();

  const langInfo = state.lang === lang ? infos['/site/langInfo'] : undefined;
  const langInfoError: string = state.lang === lang ? errors['/site/langInfo'] : undefined;
  const terms = langInfo ? langInfo.terms : null;
  const nextRequest = state.requested.length === 0 ? null : state.requested[0];

  // request site info - first time and when lang changes
  useEffect(() => {
    dispatch({ type: 'CHANGE_LANG', lang });
  }, [lang, dispatch]);

  // process pending requests
  useEffect(() => {
    if (nextRequest) {
      getInfo(dispatch, lang, nextRequest);
    }
  }, [lang, nextRequest, dispatch]);

  // load i18next resource - first time and when language changed or terms are available
  useEffect(() => {
    // once the terms list has been fetched, add to terms bundle only if first time for this language
    let langIsAvailable = Array.isArray(i18next.languages) && i18next.languages[lang];
    if (!langIsAvailable && terms) {
      i18next.addResourceBundle(lang, 'translation', terms);
      langIsAvailable = true;
    }
    // change language if required and terms list is available
    if (i18next.language !== lang && langIsAvailable) {
      i18next.changeLanguage(lang);
    }
  }, [lang, terms]);

  const siteContextValue = useMemo(() => ({ lang, infos, errors, dispatch }), [lang, infos, errors, dispatch]);

  // render
  if (langInfoError) {
    return (
      <div className="container">
        <div className="text-danger">{langInfoError}</div>
      </div>
    );
  }
  if (i18n.language !== lang || !langInfo) {
    // NOTE: language terms are not available yet (do not use translation)
    return (
      <div className="container">
        <div className="text-info">{txLoading(lang)}...</div>
      </div>
    );
  }
  return (
    <SiteContext.Provider value={siteContextValue}>
      <Page langInfo={langInfo} siteContextValue={siteContextValue} />
    </SiteContext.Provider>
  );
}

function initState(props : SiteProps): SiteState {
  const { lang, preState } = props;
  // init state with standard requests
  const state : SiteState = {
    ...emptyState,
    lang,
    requested: ['/site/langInfo'],
  };
  // if infos in preState, store them in state.infos
  if (preState && preState.lang === lang && preState.infos) {
    Object.entries(preState.infos).forEach(([key, value]) => {
      state.infos[key] = value;
      if (state.requested.includes(key)) {
        state.requested = state.requested.filter((x:any) => x !== key);
      }
      postProcess(state.infos, key);
    });
  }
  return state;
}

/**
 * Fetch data from the source
 */
async function getInfo(dispatch: React.Dispatch<SiteReducerAction>, lang: string, path: string) : Promise<void> {
  const url = path.startsWith('/')
  // site level info -> always static
    ? `${backendUrl}/${lang.substring(0, 2)}${path}.json`
  // page info - check environment
    : process.env.NODE_ENV === 'production' || backendUrl
    // server or localhost:3000 -> static
      ? `${backendUrl}/${lang.substring(0, 2)}/pages/${path}.json`
    // dev.local -> call API
      : `/api/page/getPageInfo?lang=${lang}&path=${path}`;

  dispatch({ type: 'FETCH_REQUEST', path });
  console.log(`Fetching ${url}`);
  try {
    const response = await fetch(url);
    // only network errors trigger the exception (catch), others just return error info
    if (response.ok) {
      const data = await response.json();
      // await wait(5000);  // simulate delay
      dispatch({ type: 'FETCH_SUCCESS', payload: { path, data } });
    } else {
      // handle error return
      dispatch({ type: 'FETCH_FAILURE', payload: { path, message: response.statusText } });
    }
  } catch (error: any) {
    // handle network exception
    dispatch({ type: 'FETCH_FAILURE', payload: { path, message: error.message } });
  }
}

/**
 * Page cache reducer
 */
const siteContextReducer: React.Reducer<SiteState, SiteReducerAction> = (state, action) => {
  switch (action.type) {
    case 'ADD_REQUEST': {
      const paths = action.path
        .split(',')
        .filter(
          (path) => !state.infos[path]
                        && !state.requested.includes(path)
                        && !state.inProcess.includes(path),
        );
      if (paths.length === 0) {
        return state;
      }
      return {
        ...state,
        requested: [...state.requested, ...paths],
      };
    }
    case 'FETCH_REQUEST': {
      const { path } = action;
      const requested = state.requested.filter((p) => p !== path);
      const inProcess = [...state.inProcess, path];
      return {
        ...state,
        inProcess,
        requested,
      };
    }
    case 'FETCH_SUCCESS': {
      const { path } = action.payload;
      const inProcess = state.inProcess.filter((p) => p !== path);
      const newState = {
        ...state,
        inProcess,
        error: null,
      };
      if (!state.infos[path]) {
        newState.infos = { ...state.infos };
        newState.infos[path] = action.payload.data;

        postProcess(newState.infos, path);
      }
      return newState;
    }
    case 'FETCH_FAILURE': {
      const { path } = action.payload;
      const inProcess = state.inProcess.filter((p) => p !== path);
      const newState = {
        ...state,
        inProcess,
      };
      if (!state.errors[path]) {
        newState.errors = { ...state.errors };
        newState.errors[path] = action.payload.message || 'Error no identificado.';
      }
      return newState;
    }
    case 'CHANGE_LANG': {
      const { lang } = action;
      if (state.lang === lang) {
        return state;
      }
      return initState({ lang });
    }
    default: {
      // if no action found, return same state
      return state;
    }
  }
};

/**
 * Complete tree information immediately after receiving it
 */
function postProcess(infos: object, path: string) : void {
  if (path === '/site/langInfo') {
    const langInfo = infos['/site/langInfo'];
    const { searchTree } = langInfo;
    searchTree.forEach((fam: any) => {
      fam.urlX = fam.urlS;
      fam.grupos.forEach((grp: any) => {
        grp.urlX = `${fam.urlX}/${grp.urlS}`;
        grp.categs.forEach((cat: any) => {
          cat.urlX = `${grp.urlX}/${cat.urlS}`;
        });
      });
    });
  } else if (path === '/site/searchVtaEqp') {
    const { searchTree } = infos['/site/langInfo'];
    searchTree.categs = searchTree.flatMap((x:any) => x.grupos).flatMap((x:any) => x.categs);

    const searchVtaEqp = infos['/site/searchVtaEqp'];
    searchVtaEqp.forEach((catEqp: any) => {
      const cat = searchTree.categs.find((c:any) => c.cod === catEqp.codCat);
      if (cat) {
        catEqp.equipos.forEach((eqp: any) => {
          eqp.urlX = `${cat.urlX}/${eqp.urlS}`;
          eqp.nombCat = cat.nomb;
        });
        cat.equipos = catEqp.equipos;
      }
    });
  }
}
