/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup';
import { SiteContext, SiteContextValue } from '../site/SiteContext';
import { bgImageStyle, mediaUrl } from '../common/utils/utils';
import Breadcrumb from '../common/components/Breadcrumb';
import SimpleHeaderBar from '../common/components/SimpleHeaderBar';
import NoHeaderBar from '../common/components/NoHeaderBar';
import { CotizadorContext, addItemAlquiler } from '../cotizador/CotizadorContext';
import { txLoading } from '../common/utils/preTranslate';
import './AlquilerItemPanel.scss';

export default function AlquilerItemPanel({ panel }: {panel: any}) {
  const { t } = useTranslation();
  const [, cotizadorDispatch] = useContext<any>(CotizadorContext);
  const [showZoomedImage, setshowZoomedImage] = useState<boolean>(false);
  const [currentIndexImage, setcurrentIndexImage] = useState<number>(0);

  const handleZoom = (show: boolean) => {
    setshowZoomedImage(show);
    document.body.style.overflow = show ? 'hidden' : 'unset';
    if (show) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  };
  const { lang, infos, errors, dispatch } = useContext<SiteContextValue | undefined>(SiteContext) as SiteContextValue;
  const { pathname } = useLocation();

  const segm = pathname.split('/').filter((x) => x);
  let codCat = segm[segm.length - 1].split('--')[0];

  if (!codCat || codCat.length <= 3) {
    codCat = 'not-found';
  }
  const path = `/itemsAlq/${codCat.substring(0, codCat.length - 3)}/cat${codCat}`;

  // on mount: request the item info
  useEffect(() => {
    dispatch({ type: 'ADD_REQUEST', path });
  }, [path, dispatch]);

  const item = infos[path];
  const itemError = errors[path];

  return (
    <div>
      {item ? (
        <>
          <Helmet>
            <title>Tepam Rental - {item.nomb}</title>
            {item.desc && (<meta name="description" content={item.desc.replace(/<[^>]*>/g, '')} />)}
          </Helmet>
          <SimpleHeaderBar title={item.nomb} />
          <Breadcrumb urls={item.bcUrls} names={item.bcNames} />
          <div className="AlquilerItemPanel pt-3 pb-3">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-7 col-lg-6">
                  {item.imgs && item.imgs.length > 0 && (
                    <Carousel>
                      {item.imgs.map((img: string, i: number) => (
                        <Carousel.Item key={i} onClick={() => { handleZoom(true); setcurrentIndexImage(i); }}>
                          <div className="slider-item-image wf-gray-background" style={bgImageStyle(img)} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  )}
                </div>

                <div className="col-md-5 col-lg-5 pt-4 pt-md-0 ml-lg-5 mr-auto">
                  <div className="rtf" dangerouslySetInnerHTML={{ __html: item.desc }} />
                  <div className="row font-weight-bold pt-3 row">
                    <div className="col">
                      {item.txPot && (
                        <div>{t('Potencia', 'Potencia?')}: {item.txPot} HP</div>
                      )}
                      {item.txPeso && (
                        <div>{t('Peso operativo', 'Peso operativo?')}: {item.txPeso} Ton</div>
                      )}
                      {item.txProf && (
                        <div>{t('Profundidad', 'Profundidad?')}: {item.txProf} m</div>
                      )}
                      {item.txCap && (
                        <div>{t('Capacidad', 'Capacidad?')}: {item.txCap}</div>
                      )}
                      {item.tracc && item.tracc !== '-' && (
                        <div>{t('Tracción', 'Tracción?')}: {item.tracc}</div>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn--primary-2 col-lg-8 mt-3"
                    onClick={() => cotizadorDispatch(addItemAlquiler(item))}
                  >
                    <span className="btn__text">{panel.buttonCopy}</span>
                  </button>

                  {item.files && item.files.length > 0 && (
                    <div className="files-container mt-md-4">
                      <ListGroup variant="flush">
                        <ListGroup.Item className="item-title font-weight-bold p-1 pl-3">
                          {panel.downloadsTitle}
                        </ListGroup.Item>
                        {item.files.map((file: any, i: number) => (
                          <a key={i} href={mediaUrl(file.url)} rel="noopener noreferrer" target="_blank">
                            <ListGroup.Item className="p-1 pl-3">{file.name}
                              <i className="icon-Download font-weight-bolder p-2" />
                            </ListGroup.Item>
                          </a>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showZoomedImage && (
              <div className="zoomed-image">
                <img
                  className="d-block m-auto"
                  src={mediaUrl(item.imgs[currentIndexImage].split(/[?#]/)[0])}
                  alt="First slide"
                />
                <button type="button" className="btn btn--xs btn-danger rounded-pill"
                  onClick={() => handleZoom(false)}
                >X
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <NoHeaderBar />
          <div className="container">
            {itemError ? (
              <div className="text-danger">{itemError}</div>
            ) : (
              <div className="text-info">{txLoading(lang)}...</div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
