/* eslint-disable react/no-danger */
import React from 'react';
import './SimpleTextPanel.scss';

export default function SimpleTextPanel({ panel }: {panel: any}) {
  return (
    <div className={'SimpleTextPanel pt-5 pb-5 ' + (panel.hasGrayBackground ? ' hasGrayBackground' : '')}>
      <div className="container">
        <h1>{panel.title}</h1>
        <h2>{panel.subtitle}</h2>
        <div className="SimpleTextPanel__body rtf" dangerouslySetInnerHTML={{ __html: panel.body }} />
      </div>
    </div>
  );
}
