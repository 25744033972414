import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function ConfirmModal(props: any) {
  const { onClickCancel, onClickConfirm, message } = props;
  const { t } = useTranslation();

  return (
    <Modal
      className="confirm-modal"
      show
      backdrop="static"
      keyboard={false}
      onHide={onClickCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn type--uppercase"
          onClick={onClickCancel}
        >
          <span className="btn__text">{t('Cancelar', 'Cancelar')}</span>
        </button>
        <button
          type="button"
          onClick={onClickConfirm}
          className="btn btn--primary type--uppercase"
        >
          <span className="btn__text">{t('Confirmar', 'Confirmar')}</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
