import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PreState } from './site/Site';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './index.scss';

const preState = getPreState();

ReactDOM.render(
  <App preState={preState} />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// server-side state preloading
function getPreState() : PreState {
  const script = document.getElementById('preState');
  return script ? JSON.parse(script.innerHTML) : {};
}
