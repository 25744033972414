/**
 * Build style object for background image, fixed to work on different environments
 */
export function bgImageStyle(imagePath: string) {
  if (!imagePath) {
    return {};
  }
  const newPath = imagePath.startsWith('/media')
    ? (process.env.REACT_APP_BACKEND_URL || '') + imagePath
    : (process.env.PUBLIC_URL || '') + imagePath;

  return {
    backgroundImage: `url(${newPath})`,
    opacity: 1,
  };
}

/**
 * Build URL for media item (image or file), fixed to work on different environments
 */
export function mediaUrl(imagePath: string) {
  if (!imagePath) {
    return '';
  }
  const newPath = imagePath.startsWith('/media')
    ? (process.env.REACT_APP_BACKEND_URL || '') + imagePath
    : (process.env.PUBLIC_URL || '') + imagePath;
  return newPath;
}

/**
 * Format number as currency
 */
export function numberToCurrency(number: number, lang: string) {
  return (`US$ ${new Intl.NumberFormat(lang).format(number)}`);
}

/**
 * Convert string to lower-case, with no accented characters (transform) and no special characters (remove)
 * i.e. "Qué es?" --> "que es"
 */
export function toLowerAscii(text: string) {
  return text.toLowerCase();
}
