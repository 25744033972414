import React from 'react';
import './SimpleHeaderBar.scss';

export default function SimpleHeaderBar(props: any) {
  const { title } = props;

  return (
    <div className="SimpleHeaderBar">
      <div className="header-box">
        <div className="nav-spacer-box" />
        <div className="bar-position-box">
          <div className="bar-absolute-box wf-visible">
            <div className="bar-title-box container">
              <div className="bar-color-box">
                <div className="row no-gutters box-container">
                  <h1 className="bar-title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
