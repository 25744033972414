import React from 'react';

export default function TextPanel({ panel }: {panel: any}) {
  const { panelType, title } = panel;
  return (
    <div className="container">
      <h3>{panelType}: {title}</h3>
    </div>
  );
}
