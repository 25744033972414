/* eslint-disable import/prefer-default-export */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import { CotizadorProvider } from './cotizador/CotizadorContext';
import Site, { PreState } from './site/Site';

import './App.scss';

type AppProps = {
  preState: PreState;
};

/**
 * The App is the root component of the site, only rendered once at boot.
 */
export default function App({ preState }: AppProps) {
  // const history = createBrowserHistory();
  return (
    <CotizadorProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/es" />
          </Route>
          <Route path="/es"><Site {...{ lang: 'es-ES', preState }} /></Route>
          <Route path="/en"><Site {...{ lang: 'en-US', preState }} /></Route>
        </Switch>
      </Router>
    </CotizadorProvider>
  );
}
