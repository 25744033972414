import React from 'react';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import { bgImageStyle } from '../../common/utils/utils';

export default function VentaGrupos({ data, t }: {data: any; t: TFunction<string>}) {
  const { familia, basePath }: {familia: any, basePath: string} = data;
  const { grupos } = familia;
  const query = data.search || '';

  const disponibles = t('Disponibles', 'Disponibles?').toLowerCase();

  return (
    <div className="row">
      {grupos.filter((grp: any) => grp.cantV > 0).map((grp: any) => (
        <div key={grp.cod} className="column-item bg--secondary mb-4 border--round grpily-item">
          <Link to={basePath + '/' + grp.urlX + query} className="block w-100">
            <div className="mb-0 feature feature-7 boxed text-center imagebg" data-overlay="3">
              <div className="background-image-holder" style={bgImageStyle(grp.img)} />
              <h4 className="pos-vertical-center">{grp.nomb}</h4>
            </div>
            <div className="available-label text-center p-2">{grp.cantV + ' ' + disponibles}</div>
          </Link>
        </div>
      ))}
    </div>
  );
}
