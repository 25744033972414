import React from 'react';
import PanelFactory from '../utils/panelFactory';

export default function PanelWrapper({ langInfo, pageInfo, panel }
    : { langInfo:any, pageInfo:any, panel: any }) {
  if (panel.error) {
    return <div className="text-danger">{panel.error}</div>;
  }
  const panelComponent = PanelFactory(panel.panelType);
  if (!panelComponent) {
    return <div className="text-danger">No React component implemented for panel '{panel.panelType}' (front-end)</div>;
  }
  return (
    <div className={`panel ${panel.hasGrayBackground ? 'hasGrayBackground' : ''}`}>
      {React.createElement(panelComponent, { langInfo, pageInfo, panel })}
    </div>
  );
}
